import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { EditOrderDialogComponent } from '@app/order/edit-order-dialog/edit-order-dialog.component';
import { TermsService } from './terms.service';

@Component({
  selector: 'app-terms-popup',
  templateUrl: './terms-popup.component.html',
  styleUrls: ['./terms-popup.component.scss'],
})
export class TermsPopupComponent implements OnInit {
  tcUrl = '';
  tncVersion: number;
  privacyUrl = '';

  constructor(
    public dialogRef: MatDialogRef<EditOrderDialogComponent>,
    private router: Router,
    private termsService: TermsService
  ) {}

  ngOnInit(): void {
    this.termsService.getTerms().subscribe(
      (res) => {
        this.tcUrl = res.url;
        this.tncVersion = res.version;
      },
      (err) => {}
    );
    this.termsService.getPrivacyPolicy().subscribe(
      (res) => {
        this.privacyUrl = res.url;
      },
      (err) => {}
    );
  }

  accept() {
    this.termsService.accept(this.tncVersion).subscribe(
      (res) => {
        this.router.navigateByUrl('/').finally(() => {
          this.dialogRef.close();
          location.reload();
        });
      },
      (err) => {}
    );
  }
}
