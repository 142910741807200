import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/auth';

const routes = {
  terms: () => `/org/profile/configuration/TNC/currentVersion`,
  privacy: () => '/org/profile/configuration/PrivacyPolicy/currentVersion',
  accept: (tncVersion: number) => `/org/profile/configuration/TNC/Accept/${tncVersion}`,
};

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService) {}

  getTerms(): Observable<any> {
    return this.httpClient.get(routes.terms(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getPrivacyPolicy(): Observable<any> {
    return this.httpClient.get(routes.privacy(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  accept(tncVersion: number): Observable<any> {
    return this.httpClient.post(
      routes.accept(tncVersion),
      {},
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }
}
