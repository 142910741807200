<div class="feedback-dialog-container">
  <div>
    <button class="close-btn" mat-button color="warn" (click)="cancel()">X Close</button>
  </div>
  <img src="/assets/icons/iot-logo.png" class="w-25" />
  <h3 class="header">Tell us your feedback</h3>
  <form [formGroup]="feedbackForm">
    <p>How was your experience?</p>
    <mat-radio-group formControlName="rate">
      <mat-label>
        <mat-radio-button class="smiley-radio" *ngFor="let rate of rating" [value]="rate.rate">
          <p [class]="rate.smiley"></p>
        </mat-radio-button>
      </mat-label>
    </mat-radio-group>
    <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="d-block">
      <mat-label>Title</mat-label>
      <input type="text" matInput formControlName="title" placeholder="Title" />
      <mat-error *ngIf="feedbackForm.controls.title.invalid && feedbackForm.controls.title.touched">
        <span translate>Title is required</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="d-block">
      <mat-label>Feedback</mat-label>
      <textarea matInput formControlName="feedback" placeholder="Tell us your feedback"></textarea>
      <mat-error *ngIf="feedbackForm.controls.feedback.invalid && feedbackForm.controls.feedback.touched">
        <span translate>Feedback is required</span>
      </mat-error>
    </mat-form-field>
  </form>
  <div class="feedback-buttons">
    <button mat-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isValid() || isLoading">
      Submit
      <app-loader [isLoading]="isLoading"></app-loader>
    </button>
  </div>
</div>
