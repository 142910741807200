import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { I18nModule } from '@app/i18n';
import { MaterialModule } from '@app/material.module';
import { AuthModule } from '@app/auth';
import { ShellComponent } from './shell.component';

import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { SharedModule } from '@app/@shared';
import { QuickActionModule } from '@app/quick-action/quick-action.module';
import { FeedbackModule } from '@app/feedback/feedback.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    AuthModule,
    I18nModule,
    RouterModule,
    NgMaterialMultilevelMenuModule,
    QuickActionModule,
    NgxSkeletonLoaderModule.forRoot(),
    FeedbackModule,
  ],
  declarations: [ShellComponent],
})
export class ShellModule {}
