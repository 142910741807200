import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from '@app/@core/services/core.service';
import { environment } from '@env/environment';
import { FeedbackService } from '../feedback.service';
import { CountriesService } from '@app/@core/services/countries.service';
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit, AfterViewInit {
  feedbackForm: FormGroup;
  credentials = JSON.parse(localStorage.getItem('credentials'));
  isLoading = false;
  rating = [
    {
      smiley: 'happy',
      rate: 5,
    },
    {
      smiley: 'normal',
      rate: 2.5,
    },
    {
      smiley: 'sad',
      rate: 0,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private _formBuilder: FormBuilder,
    private feedbackService: FeedbackService,
    private _snackBar: MatSnackBar,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  ngAfterViewInit(): void {
    // if the popup appeared once, don't display again
    this.coreService.setCookie('last_feedback', 30);
  }

  setForm() {
    this.feedbackForm = this._formBuilder.group({
      title: [''],
      feedback: [''],
      companyId: [this.credentials.companyId],
      companyName: [this.credentials.companyName],
      rate: [5],
      country: [CountriesService.countrySettings.country],
      applicationName: [environment.appName],
      createdDate: [new Date().toLocaleString()],
    });
  }

  isValid() {
    return this.feedbackForm.valid;
  }

  submit() {
    this.isLoading = true;
    if (this.isValid()) {
      this.feedbackService.addFeedback(this.feedbackForm.value);
      this.feedbackForm.reset();
      this.isLoading = false;
      this.dialogRef.close();
      this._snackBar.open('Your feedback has been submitted. Thank You!', null, {
        duration: 2000,
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
