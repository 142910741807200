import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { OrderService } from '../order/order.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SupportConfigService } from '@app/@core/services/support-config.service';
import { ContactInfo } from '@app/models/contact-info.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  env = environment;
  isLoading = false;
  counters: any;
  showBanner = false;
  loaded = false;
  contactInfo: ContactInfo;

  constructor(
    private router: Router,
    private supportConfigService: SupportConfigService,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.getSupportConfigInfo();
    this.orderService.getCounters().subscribe(
      (res: any) => {
        this.counters = res;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getSupportConfigInfo() {
    this.supportConfigService
      .getSupportConfig()
      .pipe(take(1))
      .subscribe((response: ContactInfo) => {
        this.contactInfo = response;
      });
  }

  goToOrders() {
    this.router.navigateByUrl('/order');
  }

  navigateTo(link: string) {
    this.router.navigateByUrl(link);
  }
}
