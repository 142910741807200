<div class="row counter">
  <div class="col-sm-4 m-col">
    <button class="btn btn-default" (click)="add(-1)" [disabled]="count <= min">-</button>
  </div>
  <div class="col-sm-4 m-col count-col">
    <p>{{ count }}</p>
  </div>
  <div class="col-sm-4 m-col">
    <button class="btn btn-default" (click)="add(1)" [disabled]="count >= max">+</button>
  </div>
</div>
