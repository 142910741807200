import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface WarningData {
  message: string;
  hideImage: boolean;
  proceedBtnLabel: string;
  cancelBtnLabel: string;
  header: string;
  image: string;
  type?: string;
  onProceed: () => void;
  onDiscard: () => void;
}

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent implements OnInit {
  private defaultTypes = {
    app_promotion: {
      header: 'Hi :)',
      message: 'We offer better experience in our mobile apps',
      image: 'assets/icons/iot-logo.png',
      hideImage: false,
      proceedBtnLabel: 'Try it',
      cancelBtnLabel: 'I am good',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningData
  ) {}

  ngOnInit(): void {
    if (this.data.type && this.defaultTypes[this.data.type]) {
      const type: any = this.defaultTypes[this.data.type];
      this.data.header = type.header;
      this.data.message = type.message;
      this.data.image = type.image;
      this.data.hideImage = type.hideImage;
      this.data.proceedBtnLabel = type.proceedBtnLabel;
      this.data.cancelBtnLabel = type.cancelBtnLabel;
    }
  }

  proceed() {
    this.data.onProceed();
    this.dialogRef.close();
  }

  cancel() {
    this.data.onDiscard();
    this.dialogRef.close();
  }
}
