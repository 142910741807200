import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [FeedbackDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, SharedModule],
})
export class FeedbackModule {}
