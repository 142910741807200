import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { CreateCompanyComponent } from './register/create-company/create-company.component';
import { BasicInfoComponent } from './register/create-account/basic-info/basic-info.component';
import { CreateAccountComponent } from './register/create-account/create-account.component';
import { VerifyOtpComponent } from './register/create-account/verify-otp/verify-otp.component';
import { CompanyBasicInfoComponent } from './register/create-company/company-basic-info/company-basic-info.component';
import { CompanySectorComponent } from './register/create-company/company-sector/company-sector.component';
import { CompanyDocumentsComponent } from './register/create-company/company-documents/company-documents.component';
import { CompanyUnderApprovalComponent } from './register/create-company/company-under-approval/company-under-approval.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordDialogComponent } from './reset-password/reset-password-dialog/reset-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    I18nModule,
    AuthRoutingModule,
    NgOtpInputModule,
  ],
  declarations: [
    LoginComponent,
    BasicInfoComponent,
    CreateAccountComponent,
    CreateCompanyComponent,
    VerifyOtpComponent,
    CompanyBasicInfoComponent,
    CompanySectorComponent,
    CompanyDocumentsComponent,
    CompanyUnderApprovalComponent,
    PasswordStrengthComponent,
    ResetPasswordComponent,
    ResetPasswordDialogComponent,
  ],
})
export class AuthModule {}
