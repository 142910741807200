<div class="error-div">
  <img class="bi bi-exclamation-circle-fill error-img" src="{{ errorCode ? errorCode.img : img }}" />
  <h3 *ngIf="!errorCode">Sorry Something is not right!</h3>
  <p class="msg" [innerHtml]="errorCode ? errorCode.msg : errorMsg"></p>
  <button *ngIf="data.action" mat-stroked-button color="primary" (click)="action()">{{ data.action.name }}</button>
  <button *ngIf="errorCode" mat-raised-button (click)="close()">GO BACK</button>
  <button
    *ngIf="errorCode"
    mat-raised-button
    color="primary"
    (click)="errorCode?.action(errorCode?.errorObject?.responseObject)"
  >
    {{ errorCode.btnText }}
  </button>
  <p class="support-p" *ngIf="!errorCode">
    if you still have an issue, contact out support team <a href="{{ env.supportEmail }}">{{ env.supportEmail }}</a>
  </p>
</div>
