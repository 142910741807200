import { Component, OnInit } from '@angular/core';
import { StorageKey } from '@app/@core/services/storage/storage-key';
import { StorageService } from '@app/@core/services/storage/storage.service';
import { DomainInfo } from '@app/models/domain-info.model';
import { environment } from '@env/environment';
import { CountriesService } from '@app/@core/services/countries.service';
@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit {
  step = 1;
  companyData: any;
  country = CountriesService.countrySettings.country;
  env = environment;
  companyLogoUrl: string = '/assets/icons/iot-logo.png';
  isCompanyLoading: boolean = true;
  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    this.setCompanyDetails();
  }

  setCompanyDetails() {
    const domainInfo: DomainInfo = this.storageService.getData(StorageKey.domainInfo);
    if (domainInfo?.isValidDomain) {
      this.companyLogoUrl = domainInfo.companyLogoUrl || this.companyLogoUrl;
      this.env.solutionOwner = domainInfo.companyName || this.env.solutionOwner;
    }
    this.isCompanyLoading = false;
  }

  proceedFromBasicData(data: any) {
    this.step = 2;
    this.companyData = data;
  }

  proceedFromSector(data: any) {
    this.step = 3;
    this.companyData.interests = data.interests.join(',');
    this.companyData.role = data.role;
    this.companyData.sector = data.sector;
  }
}
