<div class="container-fluid">
  <div class="row m-row">
    <div class="col-lg-5 right-panel" *ngIf="!isLoading">
      <img width="100px" class="logo-img" [src]="companyLogoUrl" />
      <p class="title">Discover {{ env.solutionOwner }} logistics services in {{ country }}</p>
      <div class="sm-line"></div>
      <img class="left-intro" src="/assets/images/ahoy-intro.svg" />
    </div>
    <div class="col-lg-7 main-content" *ngIf="isAccepted">
      <div class="main-div">
        <img src="/assets/images/terms.svg" />
        <h3>Your company is under review</h3>
        <p class="main-p">We will contact you when your company approved</p>
        <p class="contact-p">
          if you have any questions contact us at
          <a *ngIf="contactInfo?.email" href="{{ contactInfo.email }}">{{ contactInfo.email }}</a>
          <span *ngIf="!contactInfo?.email">N/A</span>
        </p>
      </div>
    </div>
    <div class="col-lg-7 main-content" *ngIf="!isAccepted">
      <div class="main-div">
        <img src="/assets/images/terms.svg" />
        <h3>Your company been rejected</h3>
        <p class="contact-p">
          if you have any questions contact us at
          <a *ngIf="contactInfo?.email" href="{{ env.supportEmail }}">{{ env.supportEmail }}</a>
          <span *ngIf="!contactInfo?.email">N/A</span>
        </p>
      </div>
    </div>
  </div>
</div>
