<div mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="container text-center">
    <img [src]="src ? src : '/assets/images/subscription-created.svg'" />
    <br />
    <h4>{{ successMsg }}</h4>
    <br />
    <p [innerHtml]="msgBody"></p>
    <br />
    <button mat-raised-button color="primary" (click)="viewDetails()">VIEW DETAILS</button>
  </div>
</div>
