import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { QuickActionsService } from '../../quick-actions.service';
import { finalize } from 'rxjs/operators';
import { GlobalEventsService } from '@app/global-events.service';

@Component({
  selector: 'app-customer-paid-action',
  templateUrl: './customer-paid-action.component.html',
  styleUrls: ['./customer-paid-action.component.scss'],
})
export class CustomerPaidActionComponent implements OnInit, AfterViewInit {
  isLoading = false;

  @Input() order: any;

  action: any;

  constructor(private quickActionsService: QuickActionsService, private globalEventsService: GlobalEventsService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.action = activeActions && activeActions.length > 0 ? activeActions[0] : null;
  }

  rejectRequest() {
    this.respond(false);
  }

  removeAmount() {
    this.respond(true);
  }

  private respond(approve: boolean) {
    const body = {
      quickActionId: this.action.quickActionId,
      approve,
    };
    this.isLoading = true;
    this.quickActionsService
      .removePayment(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.globalEventsService.onQuickActionResolved.emit(this.action);
        })
      )
      .subscribe(
        (res: any) => {},
        (err: any) => {}
      );
  }
}
