import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalEventsService {
  onPromptToAcceptTerms = new EventEmitter<any>();
  onPlanEnrollment = new EventEmitter<any>();
  onLoadCompanyPlan = new EventEmitter<any>();
  onQuickActionResolved = new EventEmitter<any>();
  onSideNavOpened = new EventEmitter<any>();
  // special event emitter for API errors,
  // it parses the request and the response and record only the needed information
  onAPIInterceptorError = new EventEmitter<any>();
  // should be called whenever you need to log data for analytics
  onAnalyticsLog = new EventEmitter<any>();

  onUnauthorizedException = new EventEmitter<any>();
}
