import { EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreService } from '@app/@core/services/core.service';
import { CredentialsService } from '@app/auth';
import { AuthenticationService } from '@app/auth/authentication.service';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Config } from 'ng-otp-input/lib/models/config';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOtpComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;
  error: string | undefined;

  isLoading = false;
  verifyOtpForm: FormGroup;

  @Input() accountData: any;

  @Output() back = new EventEmitter<any>();

  otpConfig: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
    inputStyles: {
      'font-size': '18px',
      'margin-bottom': '0.5em',
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.verifyOtpForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  resendOtp() {
    this.isLoading = true;
    this.verifyOtpForm.reset('');
    this.ngOtpInput.otpForm.reset('');
    if (('' + this.accountData.phoneNumber).charAt(0) !== '+') {
      this.accountData.phoneNumber = `+${this.accountData.phoneNumber}`;
    }
    this.authService
      .sendRegistrationOtp(this.accountData.phoneNumber)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {},
        (err) => {}
      );
  }

  goBack() {
    this.verifyOtpForm.reset('');
    this.ngOtpInput.otpForm.reset('');
    this.back.emit();
  }

  verifyOTP() {
    if (this.verifyOtpForm.valid && this.ngOtpInput.otpForm.valid && this.accountData.phoneNumber) {
      const code = this.verifyOtpForm.value.code;
      const phone = this.accountData.phoneNumber;
      this.isLoading = true;
      this.authService.verifyRegistrationOtp(code, phone).subscribe(
        (res: any) => {
          this.registerAccount(res?.registrationToken);
        },
        (err) => {
          this.error = 'Incorrect Otp ';
          this.isLoading = false;
        }
      );
    }
  }

  private registerAccount(registrationToken: string) {
    this.authService.register(registrationToken, this.accountData).subscribe(
      (res) => {
        this.saveCredentials(res);
      },
      (err) => {
        this.coreService.showErrorDialog(err);
        this.isLoading = false;
      }
    );
  }

  private saveCredentials(credentials: any) {
    const data = {
      username: this.accountData.email,
      token: credentials.accessToken,
      companyId: '0',
      refreshToken: credentials.refreshToken,
    };
    this.authService.setCredentials(data);
    this.router.navigateByUrl('/createCompany');
  }

  onOtpChange(e: any) {
    if (e.length === this.otpConfig.length) {
      this.verifyOtpForm.get('code').setValue(e);
    } else {
      this.verifyOtpForm.get('code').setValue(null);
    }
  }

  isOtpValid() {
    if (this.verifyOtpForm && this.ngOtpInput?.otpForm) {
      this.verifyOtpForm.markAllAsTouched();
      this.ngOtpInput.otpForm.markAllAsTouched();
      return this.verifyOtpForm.valid && this.ngOtpInput.otpForm.valid;
    }
  }
}
