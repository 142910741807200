import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '../auth';
import { Observable } from 'rxjs';

const routes = {
  getActions: (statuses: string) => `/org/merchant/QuickAction/All?statusIds=${statuses}`,
  removePayment: () => `/org/merchant/QuickAction/RemovePayment`,
  customerUnableToPay: () => `/org/merchant/QuickAction/CustomerUnableToPay`,
  updateCodAmount: () => `/org/merchant/QuickAction/UpdateCODAmount`,
  allowCardPayment: () => `/org/merchant/QuickAction/AllowCardPayment`,
  updateBoxQuantity: () => `/org/merchant/QuickAction/approveNewBoxQuantity`,
};

@Injectable({
  providedIn: 'root',
})
export class QuickActionsService {
  updateBoxQuantity(quickActionId: number, approve: boolean): Observable<any> {
    return this.httpClient.put(
      routes.updateBoxQuantity(),
      {
        quickActionId: quickActionId,
        approve: approve,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }
  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService) {}

  getActions(statuses: string): Observable<any> {
    return this.httpClient.get(routes.getActions(statuses), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  removePayment(body: any): Observable<any> {
    return this.httpClient.put(routes.removePayment(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  updateCodAmount(body: any): Observable<any> {
    return this.httpClient.put(routes.updateCodAmount(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  allowCardPayment(body: any): Observable<any> {
    return this.httpClient.put(routes.allowCardPayment(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  customerUnableToPay(body: any): Observable<any> {
    return this.httpClient.put(routes.customerUnableToPay(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }
}
