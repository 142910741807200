import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { environment } from '@env/environment';
import { slide } from '@app/animations';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '@app/feedback/feedback-dialog/feedback-dialog.component';
import { PermissionsService } from '@app/settings/permissions/permissions.service';
import { Logger } from '@app/@core';
import { SettingsService } from '@app/settings/settings.service';
import { GlobalEventsService } from '@app/global-events.service';
import { StorageService } from '@app/@core/services/storage/storage.service';
import { StorageKey } from '@app/@core/services/storage/storage-key';
import { DomainInfo } from '@app/models/domain-info.model';

const log = new Logger('ShellComponent');
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [slide],
})
export class ShellComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  version: string | null = environment.version;
  year = new Date().getFullYear();
  env = environment;
  companyLogoUrl: string = 'assets/icons/iot-logo.png';

  darkMode: boolean = false;
  date = new Date();
  timezone = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' })
    .formatToParts(this.date)
    .find((part) => part.type == 'timeZoneName').value;

  menuOptions = [
    {
      label: 'Home',
      icon: '/assets/images/side-menu/home.svg',
      link: '/home',
      enabled: true,
    },
    {
      label: 'Orders',
      icon: '/assets/images/side-menu/order.svg',
      link: '/order',
      enabled: true,
    },
    {
      label: 'Subscription',
      icon: '/assets/images/side-menu/subscription.svg',
      link: '/subscription',
      badge: 'BETA',
      enabled: this.env.featuresAvailability.subscription,
    },
    {
      label: 'Bulk Order',
      icon: '/assets/images/side-menu/services.svg',
      link: '/services',
      badge: 'NEW',
      enabled: this.env.featuresAvailability.bulk,
    },
    {
      label: 'E-Commerce',
      icon: '/assets/images/side-menu/ecommerce.svg',
      link: '/ecommerce/shopify',
      enabled: true,
    },
    {
      label: 'Configurations',
      icon: '/assets/images/side-menu/configuration.svg',
      link: '/settings',
      enabled: true,
    },
    {
      label: 'About',
      icon: '/assets/images/side-menu/about.svg',
      link: '/about',
      enabled: true,
    },
  ];
  isInTrialPeriod: boolean = false;
  trialEndDate: Date = new Date();
  remainingDaysInTrial: number;
  remainingTrialTimeText: string = '';
  isBulkCreationDisabled: boolean = false;

  constructor(
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private settingService: SettingsService,
    private media: MediaObserver,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
    private storageService: StorageService,
    private globalEventsService: GlobalEventsService
  ) {}

  ngOnInit() {
    log.debug('initializing shell component....');
    this.setCompanyLogo();
    this.addCODForMenuPerUserPermission();
    this.addInvoiceForMenuPerUserPermissions();
    this.getTrialInfo();
    this.checkOnPlanEnrollment();
    this.isBulkOrderCreationEnabled();
  }

  setCompanyLogo() {
    const domainInfo: DomainInfo = this.storageService.getData(StorageKey.domainInfo);
    if (domainInfo?.isValidDomain) {
      this.companyLogoUrl = domainInfo.companyLogoUrl || this.companyLogoUrl;
    }
  }

  /* if the user have a COD manager role then show COD */
  addCODForMenuPerUserPermission() {
    if (this.permissionsService.hasAccessToCOD()) {
      this.menuOptions.splice(5, 0, {
        label: 'COD',
        icon: '/assets/images/side-menu/cod.svg',
        link: '/finance',
        enabled: true,
      });
    }
  }

  /* if the user have a financial manager role then show invoices */
  addInvoiceForMenuPerUserPermissions() {
    if (this.permissionsService.hasAccessToInvoices()) {
      this.menuOptions.splice(6, 0, {
        label: `Invoices`,
        icon: '/assets/images/side-menu/invoice.svg',
        link: '/invoice',
        enabled: true,
      });
    }
  }

  selectedItem(event: any) {
    this.router.navigateByUrl(event.link);
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  expressDelivery() {
    this.router.navigateByUrl('/order/create/express');
  }

  requestDelivery() {
    this.router.navigateByUrl('/order/create');
  }

  priorityOrder() {
    this.router.navigateByUrl('/order/create/express');
  }

  bulkOrder() {
    this.router.navigateByUrl('/bulk/create');
  }

  order() {
    this.router.navigateByUrl('/order/create');
  }

  subscription() {
    this.router.navigateByUrl('/subscription/plan/create');
  }

  submitFeedbackDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      width: '600px',
    });
  }

  changeTheme() {
    this.darkMode = !this.darkMode;
    if (this.darkMode === true) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  getTrialInfo() {
    this.settingService.getTrialInfo().subscribe((res) => {
      res?.isActive ? (this.isInTrialPeriod = res?.isActive) : (this.isInTrialPeriod = false);
      this.trialEndDate.setTime(res?.endDate);
    });
  }

  checkOnPlanEnrollment() {
    this.globalEventsService.onPlanEnrollment.subscribe(() => {
      this.isInTrialPeriod = false;
    });
  }

  menuBtnClicked() {
    this.sidenav.toggle();
    this.globalEventsService.onSideNavOpened.next(this.sidenav.opened);
  }

  isBulkOrderCreationEnabled() {
    this.settingService.isBulkOrderEnabled().subscribe((res) => {
      this.isBulkCreationDisabled = res;
    });
  }
}
