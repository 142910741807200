import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creation-success-dialog',
  templateUrl: './creation-success-dialog.component.html',
  styleUrls: ['./creation-success-dialog.component.scss'],
})
export class CreationSuccessDialogComponent implements OnInit {
  successMsg = '';
  Id = 0;
  detailsRoute = '';
  mainRoute = '';
  msgBody = '';
  src = '';

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreationSuccessDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data instanceof String) {
      this.successMsg = this.data.toString();
    }
    if (this.data instanceof Object) {
      this.successMsg = this.data.success;
      this.Id = this.data.Id;
      this.msgBody = this.data.msgBody;
      this.detailsRoute = this.data.detailsRoute;
      this.mainRoute = this.data.mainRoute;
      this.src = this.data.src;
    }
  }

  viewDetails() {
    this.router.navigateByUrl(`/${this.detailsRoute}/${this.Id}`);
    this.dialogRef.close();
  }

  closeDialog() {
    this.router.navigateByUrl(`/${this.mainRoute}`);
    this.dialogRef.close();
  }
}
