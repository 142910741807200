import { trigger, state, transition, style, animate } from '@angular/animations';

export let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  //void <=> *
  transition(':enter, :leave', [animate('750ms 0ms ease-out')]),
]);

export let slide = trigger('slide', [
  state('void', style({ transform: 'translateY(50px)' })),
  transition(':enter, :leave', [animate('750ms 0ms ease-out')]),
]);

export let swipe = trigger('swipe', [
  transition(':enter', [style({ transform: 'translateX(1000px)' }), animate('500ms 300ms ease-out')]),
  transition(':leave', [style({ transform: 'translateX(300px)' }), animate('200ms 0ms ease-out')]),
]);

export let slideInOut = trigger('slideInOut', [
  transition(':enter', [style({ transform: 'translateX(1000px)' }), animate('500ms 300ms ease-out')]),
  transition(':leave', [style({ opacity: 0 }), animate('300ms 0ms ease-out')]),
]);
