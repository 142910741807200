<div class="container-fluid">
  <div class="row m-row">
    <div class="col-lg-5 right-panel">
      <img width="100px" class="logo-img" [src]="companyLogoUrl" />
      <p class="title" *ngIf="!isCompanyLoading">
        Discover {{ env.solutionOwner }} logistics services in {{ country.name }}
      </p>
      <div class="sm-line"></div>
      <p class="sign-in-p">Sign-in to the future of logistics</p>
      <img class="left-intro" src="/assets/images/ahoy-intro.svg" />
    </div>
    <div class="col-lg-7 main-content">
      <div>
        <p class="ahoy-link">Don't have an account? <a href="/register">Register</a></p>
      </div>
      <!-- Login user name and password -->
      <div *ngIf="state === 1" class="form-div custom-card">
        <h3>Welcome to {{ env.solutionOwner }} Community</h3>
        <p class="sub-label">Together.. we are changing movement</p>
        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div class="login-error" [hidden]="!error || isLoading" translate>Username or password incorrect.</div>
          <div class="login-fields" fxLayout="column">
            <!-- <p class="field-label">Email / Phone Number</p> -->
            <mat-form-field [hideRequiredMarker]="true" appearance="outline">
              <mat-label>Email / Phone Number</mat-label>
              <input
                type="text"
                matInput
                formControlName="username"
                autocomplete="username"
                [placeholder]="'Email or Phone Number' | translate"
                required
              />
              <mat-hint>Hint: {{ country.code + 'XXXXXXXXX' }}</mat-hint>
              <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                <span translate>Username is required</span>
              </mat-error>
            </mat-form-field>
            <!-- <p class="field-label m-t-20">Password</p> -->
            <mat-form-field class="password-field" [hideRequiredMarker]="true" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                autocomplete="current-password"
                [placeholder]="'Password' | translate"
                required
              />
              <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                <span translate>Password is required</span>
              </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid || isLoading">
              <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
              <span translate>Login</span>
            </button>
            <p *ngIf="env.featuresAvailability.resetPassword" class="forgot-password">
              Forgot your password ?
              <a [href]="forgetPasswordLink">Reset your password</a>
            </p>
          </div>
        </form>
      </div>

      <!-- OTP choose number -->
      <div *ngIf="state === 2" class="form-div custom-card">
        <h3>Please verify your phone number</h3>
        <p class="sub-label">OTP will be sent to the selected number</p>

        <form (ngSubmit)="sendOtp()" [formGroup]="otpForm" novalidate>
          <div class="login-fields" fxLayout="column">
            <!-- <p class="field-label m-t-20">Select your phone number</p> -->
            <mat-form-field [hideRequiredMarker]="true" appearance="outline">
              <mat-label>Select Phone Number</mat-label>
              <mat-select formControlName="phone" required>
                <mat-option *ngFor="let phone of phones" value="{{ phone.phoneNumber }}">
                  {{ phone.phoneNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" [disabled]="otpForm.invalid || isLoading">
              <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
              <span translate>Request OTP</span>
            </button>
          </div>
        </form>
      </div>

      <!-- OTP verify -->
      <div *ngIf="state === 3" class="form-div custom-card">
        <h3>Please verify your phone number</h3>
        <p class="otp-sub-label">OTP will be sent to the selected number</p>

        <form (ngSubmit)="verifyOTP()" [formGroup]="verifyOtpForm" novalidate>
          <div class="login-error" [hidden]="!error || isLoading" translate>OTP code is incorrect.</div>
          <div class="login-fields text-center" fxLayout="column">
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
            <br />
            <button mat-raised-button color="primary" type="submit" [disabled]="!isOtpValid() || isLoading">
              <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
              <span translate>Verify OTP</span>
            </button>

            <p class="forgot-password">
              Didn't receive the OTP yet?
              <a class="resend-otp-link" (click)="resendOtp()">Resend OTP?</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
