import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { I18nService } from '@app/i18n';
import { GlobalEventsService } from './global-events.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsPopupComponent } from './terms-popup/terms-popup.component';
import { SettingsService } from './settings/settings.service';
import { AuthenticationService, CredentialsService } from './auth';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MonitoringService } from './@core/services/monitoring.service';

import { WarningDialogComponent } from './@shared/dialogs/warning-dialog/warning-dialog.component';
import { CoreService } from './@core/services/core.service';
import { DOCUMENT } from '@angular/common';
import { CountriesService } from './@core/services/countries.service';
import { CitiesService } from './@core/services/cities.service';
const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  termsDialogDisplayed = false;
  isInitialized = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private globalEventsService: GlobalEventsService,
    public dialog: MatDialog,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private i18nService: I18nService,
    // do not remove the following services
    private settingsService: SettingsService,
    private angularFireAnalytics: AngularFireAnalytics,
    private credentialService: CredentialsService,
    private monitoringService: MonitoringService,
    private coreService: CoreService,
    private authenticationService: AuthenticationService,
    private countriesService: CountriesService,
    private citiesService: CitiesService,
    @Inject(DOCUMENT) private _document: any
  ) {}

  ngOnInit() {
    log.debug('app initialized');
    this.appInitializing();

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    if (this.coreService.UNRECOGNIZED !== this.coreService.checkOperatingSystem()) {
      this.showMobileAppPromotionDialog(this.coreService.checkOperatingSystem());
    }

    this.initiateAnalytics();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    this.globalEventsService.onPromptToAcceptTerms.subscribe((data: any) => {
      log.debug('onPromptToAcceptTerms emitted');
      this.showTermsDialog();
    });

    this.makeAuthenticatedAPICalls();
  }

  async appInitializing() {
    if (this.isInitialized) return;
    try {
      await this.countriesService.setDefaultCountry();
    } catch (error) {
      log.error(error);
    }
    this.isInitialized = true;
  }

  /**
   * On app component sometimes u need to make authenticated API
   * calls such as get the company information
   * or check if the company approved
   *
   * this calls require checks to see if the user is authenticated and if the token already refreshed,
   * all these calls are recommended to go inside this method
   *
   */
  private makeAuthenticatedAPICalls() {
    // first check if the user is logged in first
    if (this.credentialService.credentials) {
      // refresh the token next
      this.authenticationService.refreshTokenAsync().subscribe((res: boolean) => {
        if (res) {
          this.checkIfAccountNeedSetup();
        }
      });
    }
  }

  initiateAnalytics() {
    this.monitoringService.logEvent('app_opened', { example: true });

    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2GoogleAnalytics.eventTrack(environment.version, { category: 'app_opened' });
    this.angularFireAnalytics.setUserId(this.credentialService.credentials?.companyId);
  }

  showMobileAppPromotionDialog(mobileOS: number) {
    this.dialog.open(WarningDialogComponent, {
      width: '400px',
      data: {
        type: 'app_promotion',
        onProceed: () => {
          mobileOS === this.coreService.ANDROID
            ? window.open(environment.androidMerchantAppUrl, '_blank')
            : window.open(environment.iosMerchantAppUrl, '_blank');
        },
        onDiscard: () => {},
      },
    });
  }

  checkIfAccountNeedSetup() {
    combineLatest([
      this.settingsService.isEnrolledInPlan(),
      this.settingsService.isInTrialPeriod(),
      this.settingsService.isPickupLocationAdded(),
    ])
      .pipe()
      .subscribe((res) => {
        if ((!res[0] && !res[1]) || !res[2]) {
          this.router.navigateByUrl('/setup');
        }
      });
    // check location
  }

  showTermsDialog() {
    // if already handled --> return
    // terms & conditions most likely won't update more than once per application life cycle
    if (this.termsDialogDisplayed) {
      return;
    }
    this.termsDialogDisplayed = true;

    const dialogRef = this.dialog.open(TermsPopupComponent, {
      width: '750px',
      height: '750px',
      disableClose: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      log.debug('The dialog was closed');
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
