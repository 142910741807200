import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Credentials, CredentialsService } from './credentials.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalEventsService } from '@app/global-events.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CoreService } from '@app/@core/services/core.service';
import { Logger } from '@app/@core';

const log = new Logger('AuthenticationService');

const routes = {
  login: () => `/org/profile/login`,
  refreshToken: () => `/org/profile/token`,
  sendOtp: () => `/org/profile/send-otp`,
  verifyOtp: () => `/org/profile/verify-otp`,
  otpPhones: () => `/org/profile/otp-phones`,
  sendRegistrationOtp: () => `/org/profile/registration-otp`,
  verifyRegistrationOtp: () => `/org/profile/verify-registration-otp`,
  register: () => `/org/profile/MerchantRegister`,
  createCompany: () => `/org/merchant/company/create`,
  getCurrentUserAccess: () => '/org/merchant/company/currentuseraccess',
  resetPassword: () => `/org/profile/reset-password`,
};

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface UserAccess {
  // Customize received current user access here
  assignedRoles: AssignedRole[];
  isCompanyOwner?: boolean;
}
export interface AssignedRole {
  id: number;
  name: string;
  description: string;
  permissions: Permission[];
}
export interface Permission {
  id: number;
  name: string;
  description: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _token_refreshed = false;
  private refreshTokenRequest: Observable<any> = null;

  constructor(
    private credentialsService: CredentialsService,
    private httpClient: HttpClient,
    private globalEventsService: GlobalEventsService,
    private router: Router,
    private coreService: CoreService
  ) {
    globalEventsService.onUnauthorizedException.subscribe(() => {
      this.onUnauthorizedException();
    });
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    const body = new URLSearchParams();
    body.set('username', context.username);
    body.set('password', context.password);
    return this.httpClient.post(routes.login(), body.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }

  onUnauthorizedException(error: any = null) {
    const defaultErrorMsg =
      "You are unauthorized to take this action, please contact your company's admin to complete your action.";
    const errorMsg = error?.message ? error?.message : defaultErrorMsg;
    this.coreService.showErrorDialog(errorMsg);
  }

  refreshTokenAsync() {
    log.debug('refreshTokenAsync called', this._token_refreshed);
    if (!this._token_refreshed) {
      // make the observable hot so it won't create a request for each subscription
      if (!this.refreshTokenRequest) {
        let body = {
          refreshToken: this.credentialsService.credentials.refreshToken,
        };
        this.refreshTokenRequest = this.httpClient.post(routes.refreshToken(), body).pipe(share());
      }

      this.refreshTokenRequest.subscribe(
        (res) => {
          this.updateRefreshedToken(res);
          this.hasAccess();
          this.loadCompanyInformation();
          this._token_refreshed = true;
        },
        (err) => {
          this.logout().subscribe(() => {
            location.reload();
          });
        }
      );

      return this.refreshTokenRequest.pipe(map((data: any) => (data ? true : false)));
    }

    return of(true);
  }

  setCredentials(data: any) {
    this.credentialsService.setCredentials(data, true);
  }

  private loadCompanyInformation() {
    this.getCompanyDetails().subscribe(
      (res) => {
        if (res && res.length > 0) {
          const _credentials = this.credentialsService.credentials;
          _credentials.companyId = res[0].id;
          this.credentialsService.setCredentials(_credentials);
          if (res[0].statusId === 1) {
            this.router.navigateByUrl('/companyReview');
          } else if (res[0].statusId === 2) {
            this.router.navigateByUrl('/companyReview/rejected');
          }
        }
        if (res.length === 0) {
          const _credentials = this.credentialsService.credentials;
          this.credentialsService.setCredentials(_credentials);
          this.router.navigateByUrl('/createCompany');
        }
      },
      (err) => {
        console.log('T&C not accepted');
        console.log(err);
      }
    );
  }

  private updateRefreshedToken(res: any) {
    const data: Credentials = {
      companyId: this.credentialsService.credentials.companyId,
      username: this.credentialsService.credentials.username,
      token: res.accessToken,
      refreshToken: res.refreshToken,
      companyName: this.credentialsService.credentials.companyName,
    };
    this.credentialsService.setCredentials(data);
  }

  getCompanyDetails(): Observable<any> {
    // TODO: move this to the appropriate place
    // load company plan
    this.globalEventsService.onLoadCompanyPlan.emit();

    return this.httpClient.get('/org/merchant/Company/Get', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getUserAllPhoneNumbers(otpToken: string): Observable<any> {
    return this.httpClient.get(routes.otpPhones(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${otpToken}`,
      }),
    });
  }

  sendOtp(accessToken: string, phone: string): Observable<any> {
    return this.httpClient.post(
      routes.sendOtp(),
      {
        phoneNumber: phone,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
  }

  verifyOtp(accessToken: string, code: string, phoneNumber: string): Observable<any> {
    return this.httpClient.post(
      routes.verifyOtp(),
      {
        phoneNumber,
        otp: code,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
  }

  sendRegistrationOtp(phone: string) {
    return this.httpClient.post(routes.sendRegistrationOtp(), {
      phoneNumber: phone,
    });
  }

  verifyRegistrationOtp(code: string, phoneNumber: string) {
    return this.httpClient.post(routes.verifyRegistrationOtp(), {
      phoneNumber,
      otp: code,
    });
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    let userAccess: UserAccess;
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    this.setUserAccess(null);
    return of(true);
  }

  register(accessToken: string, account: any): Observable<any> {
    return this.httpClient.post(routes.register(), account, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
  }

  createCompany(companyData: any, vat: File, tradeLicense: File): Observable<any> {
    const formData = new FormData();
    formData.append('request', JSON.stringify(companyData));
    formData.append('vat', vat);
    formData.append('tradelicense', tradeLicense);
    return this.httpClient.post(routes.createCompany(), formData, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  /**
   * get current logged in user access
   */
  getCurrentUserAccess(accessToken = ''): Observable<any> {
    return this.httpClient.get(routes.getCurrentUserAccess(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken ? accessToken : this.credentialsService.credentials.token}`,
      }),
    });
  }

  /**
   * check if user has access
   */
  hasAccess() {
    this.getCurrentUserAccess().subscribe((res: any) => {
      const userAccess: UserAccess = {
        assignedRoles: res.assignedRoles,
        isCompanyOwner: res.companyOwner,
      };
      this.setUserAccess(userAccess);
    });
  }

  /**
   * store user access into local storage
   * @param userAccess
   */
  setUserAccess(userAccess: UserAccess) {
    const userAccessKey = 'userAccess';
    if (userAccess) {
      const storage = localStorage;
      storage.setItem(userAccessKey, JSON.stringify(userAccess));
    } else {
      sessionStorage.removeItem(userAccessKey);
      localStorage.removeItem(userAccessKey);
    }
  }

  /**
   * returns user access from localStorage
   */
  getUserAccess(): UserAccess {
    return JSON.parse(localStorage.getItem('userAccess'));
  }

  resetPassword(body: { emailAddress: string }) {
    return this.httpClient.post<{ emailAddress: string }>(routes.resetPassword(), body);
  }

  /**
   * @returns list of current user roles permissions
   */
  getUserPermissions(): Permission[] {
    const roles = this.getUserAccess();
    let permissions: Permission[] = [];
    roles?.assignedRoles.forEach((role) => {
      permissions = permissions.concat(role.permissions);
    });
    return permissions;
  }
}
