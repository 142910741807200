<div mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="container text-center">
    <img src="{{ img }}" />
    <br />
    <br />
    <p [innerHTML]="msg"></p>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="close()">{{ btnTxt }}</button>
      <button class="action-btn" *ngIf="data?.action?.exportPayoutUrl" mat-raised-button (click)="exportPayout()">
        EXPORT PAYOUT TRANSACTIONS
      </button>
    </div>
  </div>
</div>
