import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})

/**
 * usage example:
 *     this.dialog.open(DeleteConfirmationComponent, {
 *       width: '700px',
 *       height: '400px',
 *       data: {
 *         onConfirmation: () => {
 *           console.log('Confirmed!');
 *         }
 *       }
 *     });
 */
export class DeleteConfirmationComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  confirm() {
    if (this.data && this.data.onConfirmation) {
      this.data.onConfirmation();
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
