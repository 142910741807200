import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { APIResponseStatus } from '../api-response-status.enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from '@app/auth';
import { map, shareReplay } from 'rxjs/operators';
import { BaseStore } from './store-pattern/base-store.service';
import { CitiesService, ICity } from './cities.service';
import { environment } from '@env/environment';
export interface ICountry {
  name?: string;
  code?: string;
  id?: number;
  dial_code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends BaseStore<ICountry[]> {
  static countrySettings = {
    defaultCountryCode: environment.defaultCountryCode,
    ...environment.countrySettings,
  };

  static setSettings(
    country: ICountry,
    cities: ICity[],
    object: typeof environment.countrySettings = environment.countrySettings
  ) {
    this.countrySettings = {
      ...object,
      defaultCountryCode: country.code?.toLowerCase() ?? 'sa',
      country: country.name,
      countryCode: country.dial_code,
      cities,
    };
  }
  constructor(httpClient: HttpClient, credentialsService: CredentialsService, private citiesService: CitiesService) {
    super(httpClient, credentialsService, {
      baseUrl: '/org/merchant/PickupLocation',
      getUrl: '/countries',
    });
  }
  async setDefaultCountry(countryCode: string = environment.defaultCountryCode) {
    const defaultCountryCode = countryCode ?? 'sa';
    await this.init();
    const countries = await this.loadData().toPromise();
    const cities = await this.citiesService.getByCountryCode(defaultCountryCode).toPromise();
    if (countries) {
      const country = countries.find((c) => c.code?.toLowerCase() === defaultCountryCode);
      CountriesService.setSettings(country, cities ?? []);
    }
  }
}
