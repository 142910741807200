import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '@app/settings/settings.service';
import { EditCustomerDialogComponent } from '@app/customer/edit-customer-dialog/edit-customer-dialog.component';
import { TermsService } from '@app/terms-popup/terms.service';
import { finalize } from 'rxjs/operators';
import { CoreService } from '@app/@core/services/core.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'app-opt-in-confirmation',
  templateUrl: './opt-in-confirmation.component.html',
  styleUrls: ['./opt-in-confirmation.component.scss'],
})
export class OptInConfirmationComponent implements OnInit {
  isLoading = false;
  termsLink = '';
  env = environment;
  termForm: FormGroup = new FormGroup({
    terms: new FormControl(false, { validators: [Validators.required] }),
  });
  plansInfo: any;

  constructor(
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<OptInConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private termsService: TermsService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.plansInfo = this.data.plansInfo;
    this.getTermsLink();
  }

  getTermsLink() {
    this.termsService.getTerms().subscribe(
      (res) => {
        this.termsLink = res.url;
      },
      (err) => {
        this.coreService.showErrorDialog(err);
      }
    );
  }

  OptInPaymentOption() {
    this.isLoading = true;
    let body = {
      optionId: this.data.paymentOptionId,
      enable: true,
    };
    this.settingsService
      .togglePaymentOption(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.dialogRef.close({
            enabled: true,
          });
        },
        (err) => {
          this.coreService.showErrorDialog(err);
        }
      );
  }
}
