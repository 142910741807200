<div class="actions-list">
  <div class="action-item" *ngFor="let action of actions" (click)="goToActionDetails(action)">
    <h4 class="header">Attention required!</h4>
    <p class="order-details">
      (<b>REF:</b> #{{ action.order.companyTrackId }} <b>Order id:</b> #{{ action.order.id }})
    </p>
    <p class="issue-title">Issue</p>
    <p class="issue">{{ actionTitle(action.typeId) }}</p>
  </div>
</div>
