import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
})
export class SuccessDialogComponent implements OnInit {
  img = '';
  msg = '';
  btnTxt = '';

  constructor(public dialogRef: MatDialogRef<SuccessDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.msg = this.data.msg;
    this.img = this.data?.img ? this.data.img : '/assets/images/subscription-created.svg';
    this.btnTxt = this.data?.btnTxt ? this.data?.btnTxt : 'Ok';
  }

  exportPayout() {
    window.open(this.data?.action?.exportPayoutUrl);
  }

  close() {
    this.dialogRef.close();
  }
}
