import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
  @Input() min = 0;
  @Input() max = 200;
  @Input() count = 1;
  @Output() change = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  add(count: number) {
    this.count += count;
    this.change.emit(this.count);
  }
}
