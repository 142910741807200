import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CoreService } from '@app/@core/services/core.service';
import { PhoneService } from '@app/@core/services/phone.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { CountriesService } from '@app/@core/services/countries.service';
@Component({
  selector: 'app-register-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit {
  @Output('proceed') proceed = new EventEmitter<any>();

  error: string | undefined;
  env = environment;
  isLoading = false;
  registerForm: FormGroup;
  countryCode = CountriesService.countrySettings.countryCode;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private phoneService: PhoneService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.PhoneNumberValidator()]],
      password: ['', [Validators.required, this.validatePasswordRegex()]],
      passwordMin: { value: false, disabled: true },
      passwordDigit: { value: false, disabled: true },
      passwordSpecial: { value: false, disabled: true },
      passwordUpperCase: { value: false, disabled: true },
      passwordLowerCase: { value: false, disabled: true },
    });
  }

  validatePasswordRegex(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const password: string = control.value;
      const error = { error: { value: control.value } };
      if (password.length < 8) {
        return error;
      }
      const digit = this.coreService.RegexConstants.DIGIT_REGEX.test(password) ? 1 : 0;
      const symbol = this.coreService.RegexConstants.SYMBOL_REGEX.test(password) ? 1 : 0;
      const uppercase = this.coreService.RegexConstants.UPPERCASE_REGEX.test(password) ? 1 : 0;
      const lowercase = this.coreService.RegexConstants.LOWERCASE_REGEX.test(password) ? 1 : 0;
      return digit + symbol + uppercase + lowercase >= 3 ? null : { error: { value: control.value } };
    };
  }

  private PhoneNumberValidator(regionCode: string = undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false;

      try {
        const val = `${control.value[0] === '+' ? '' : '+'}${control.value}`;

        if (val.startsWith('0')) {
          return { wrongNumber: { value: control.value } };
        }

        // contains invalid characters
        if (val.indexOf(' ') !== -1 || val.indexOf('-') !== -1 || val.indexOf('(') !== -1) {
          return { wrongNumber: { value: control.value } };
        }

        validNumber = this.phoneService.isValidNumber(val);
      } catch (e) {}

      return validNumber ? null : { wrongNumber: { value: control.value } };
    };
  }

  register() {
    if (this.registerForm.valid) {
      this.isLoading = true;
      const accountData = this.registerForm.value;
      if (('' + accountData.phoneNumber).charAt(0) !== '+') {
        accountData.phoneNumber = `+${accountData.phoneNumber}`;
      }
      this.authService
        .sendRegistrationOtp(accountData.phoneNumber)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (res: any) => {
            this.proceed.emit(accountData);
          },
          (err: any) => {}
        );
    }
  }
}
