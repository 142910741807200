import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/@core';
import { GlobalEventsService } from '@app/global-events.service';
import { QuickActionsService } from '@app/quick-action/quick-actions.service';
import { finalize } from 'rxjs/operators';

const log = new Logger('UpdateBoxesNumberComponent');
@Component({
  selector: 'app-update-boxes-number',
  templateUrl: './update-boxes-number.component.html',
  styleUrls: ['./update-boxes-number.component.scss'],
})
export class UpdateBoxesNumberComponent implements OnInit, AfterViewInit {
  isLoading = false;

  @Input() order: any;

  action: any;

  constructor(private globalEventsService: GlobalEventsService, private quickActionService: QuickActionsService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    log.debug(this.order);
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.action = activeActions && activeActions.length > 0 ? activeActions[0] : null;
    if (this.action && this.action.customPayload) {
      try {
        log.debug(this.action);
        this.action.customPayload = JSON.parse(this.action.customPayload);
      } catch (ex) {
        log.error("can't parse the payload");
      }
    }
  }

  approve() {
    this.respond(true);
  }

  cancel() {
    this.respond(false);
  }

  respond(approve: boolean) {
    this.isLoading = true;
    this.quickActionService
      .updateBoxQuantity(this.action.quickActionId, approve)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.globalEventsService.onQuickActionResolved.emit(this.action);
        })
      )
      .subscribe((res) => {
        log.debug(res);
      });
  }
}
