<div class="card-dialog">
  <h4 class="title">
    {{ env.solutionOwner }}! Your <br />
    customer can pay {{ data.paymentOptionId === 2 ? 'by cards' : 'online' }} as well
  </h4>

  <img
    class="pos-img"
    [src]="
      data.paymentOptionId === 2 ? 'assets/images/dialogs/card-opt-in.svg' : 'assets/images/dialogs/online-opt-in.svg'
    "
  />

  <div class="plan-div" *ngIf="data?.plansInfo?.currentActivePlan">
    <img [src]="plansInfo?.currentActivePlan?.imageUrl" />
    <h5>{{ plansInfo?.currentActivePlan?.title }}</h5>
    <p>Your current plan</p>
    <h6>
      {{
        data.paymentOptionId === 2
          ? plansInfo?.currentActivePlan?.cardCODFeesPercentage
          : plansInfo?.currentActivePlan?.onlinePaymentFeesPercentage
      }}%
    </h6>
    <p>Charges on total amount of the order per transaction</p>
  </div>

  <form action="" [formGroup]="termForm">
    <mat-checkbox class="terms-lable" formControlName="terms">
      <p class="text-left mb-0">
        Please accept our <a [href]="termsLink" target="_blank">terms & conditions</a> <br />
        to be able to use the service
      </p>
    </mat-checkbox>

    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading || !termForm.controls['terms'].value"
      (click)="OptInPaymentOption()"
    >
      <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
      <span translate>ACTIVATE {{ data.paymentOptionId === 2 ? 'CARD ON DELIVERY' : 'ONLINE PAYMENT' }}</span>
    </button>
  </form>
</div>
