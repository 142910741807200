<div>
  <div class="row bar">
    <div class="col-sm-10">
      <div class="row bar-body">
        <div class="col-sm-1">
          <img src="/assets/images/actions/box-ic.svg" />
        </div>
        <div class="col-sm-8 action-body">
          <h3>BOUNTY REPORTED WRONG NUMBER OF BOXES.</h3>
          <p class="attention-required-p">
            <span class="red-span">Action is Required!</span>
            for order
            <span class="bold-span">(REF: #{{ order?.companyOrderTrackId }}, id: {{ order?.orderId }})</span>
          </p>
        </div>
        <div class="col-sm-3 right-part">
          <p class="small-title">expires at</p>
          <p>{{ action?.expiryTime | date: 'h:mm a' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11 offset-sm-1">
          <div class="row boxes-container">
            <div class="col-md-5">
              <h4 class="small-title">original boxes</h4>
              <div class="row boxes-list">
                <div class="col box-item">
                  <img src="/assets/images/actions/small-boxes.svg" />
                  <p>x{{ order?.orderSmallBoxQuantity }}</p>
                </div>
                <div class="col box-item">
                  <img src="/assets/images/actions/mid-boxes.svg" />
                  <p>x{{ order?.orderMidBoxQuantity }}</p>
                </div>
                <div class="col box-item">
                  <img src="/assets/images/actions/large-boxes.svg" />
                  <p>x{{ order?.orderLargeBoxQuantity }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-5 offset-md-2">
              <h4 class="small-title">suggested boxes</h4>
              <div class="row boxes-list">
                <div class="col box-item">
                  <img src="/assets/images/box/smallBox.svg" />
                  <p>x{{ action?.customPayload?.toBeCollectedBoxes?.small }}</p>
                </div>
                <div class="col box-item">
                  <img src="/assets/images/box/midBox.svg" />
                  <p>x{{ action?.customPayload?.toBeCollectedBoxes?.medium }}</p>
                </div>
                <div class="col box-item">
                  <img src="/assets/images/box/largeBox.svg" />
                  <p>x{{ action?.customPayload?.toBeCollectedBoxes?.large }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-2 btns">
      <div
        class="btn-div btn-red"
        [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(52) === -1 }"
        (click)="cancel()"
      >
        <p>Cancel order</p>
      </div>
      <div
        class="btn-div btn-dark-blue"
        [ngClass]="{ 'btn-disabled': action?.allowedResolutionTypeIds.indexOf(51) === -1 }"
        (click)="approve()"
      >
        <p>Approve</p>
      </div>
    </div>
  </div>
</div>
