import { Injectable } from '@angular/core';
import { countries } from '../countries';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { environment } from '@env/environment';
import { CountriesService, ICountry } from './countries.service';

export interface Phone {
  dial_code: string;
  phone: string;
}

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  countryCode = CountriesService.countrySettings.countryCode;

  constructor(private countriesService: CountriesService) {}

  getCountries(): ICountry[] {
    this.countriesService.data.sort((a: ICountry, b: ICountry) => {
      return a.dial_code > b.dial_code ? 1 : -1;
    });
    return countries;
  }

  getPhoneObject(phone: string): Phone {
    // if the phone is null return.
    if (!phone) return null;
    const codes = this.getCodesTable();
    return this.getFormattedPhone(phone, codes);
  }

  isValidNumber(phone: string, regionCode: string = undefined): boolean {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(phone, regionCode);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  private getFormattedPhone(phone: string, codesTable: { string: ICountry }) {
    const p = `${phone[0] === '+' ? '' : '+'}${phone}`;
    let i = 1;
    const max = 5;
    while (i <= max) {
      if (codesTable[p.substr(0, i)]) {
        const code = p.substr(0, i);
        return { dial_code: code, phone: p.substr(code.length) };
      }
      i++;
    }

    // default code is the country from env if no code found
    // check if the phone number starts with 0, that means the user entered the
    // local phone number without the country code, so we automatically add the country code to it
    // remove the 0 if the number start with 0
    const num = phone.startsWith('0') ? phone.substr(1) : phone;
    return { dial_code: this.countryCode, phone: num };
  }

  private getCodesTable(): { string: ICountry } {
    const codes: any = {};
    this.getCountries().forEach((country: ICountry) => {
      codes[country.dial_code] = country;
    });
    return codes;
  }

  filterCountryCode(code: string) {
    const countryCode = code.toLowerCase();
    return countries.filter((option: ICountry) => option.dial_code.toLowerCase().includes(countryCode));
  }
}
