import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { CountriesService } from '@app/@core/services/countries.service';
@Component({
  selector: 'app-update-amount',
  templateUrl: './update-amount.component.html',
  styleUrls: ['./update-amount.component.scss'],
})
export class UpdateAmountComponent implements OnInit {
  priceForm: FormGroup;
  currency = CountriesService.countrySettings.currency;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateAmountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.priceForm = this.formBuilder.group({
      CashAmount: ['', [Validators.required, this.priceValidator]],
    });
  }

  proceed() {
    const amount = this.priceForm.get('CashAmount').value;
    this.dialogRef.close({
      amount,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  /**
   * Validate the price field
   * @param c the price form control that should be validated
   */
  private priceValidator(c: FormControl) {
    if (c.value || c.value === 0) {
      // negative numbers & zero are not allowed
      if (c.value <= 0) {
        return { priceValidator: { valid: false } };
      }

      const valStr = (c.value + '').split('.');
      if (valStr.length > 2) {
        return { priceValidator: { valid: false } };
      }

      if (valStr.length === 2) {
        const dec = valStr[1];
        if (['00', '25', '5', '75'].indexOf(dec) === -1) {
          return { priceValidator: { valid: false } };
        }
      }
    }
  }
}
