import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIResponseStatus, CheckStatus } from '@app/@core/api-response-status.enum';
import { CitiesService, ICity } from '@app/@core/services/cities.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-company-basic-info',
  templateUrl: './company-basic-info.component.html',
  styleUrls: ['./company-basic-info.component.scss'],
})
export class CompanyBasicInfoComponent implements OnInit {
  @Output('proceed') proceed = new EventEmitter<any>();

  error: string | undefined;

  isLoading = false;
  companyForm: FormGroup;
  cityStore = this.citiesService.storeByCountryCode(environment.defaultCountryCode);
  checkStatus = CheckStatus;
  apiResponseStatus = APIResponseStatus;
  cities: ICity[] | undefined = undefined;
  get cities$() {
    return this.cityStore.getStoredData().pipe(map((c) => c.cities));
  }
  get citiesStatus$() {
    return this.cityStore.status$;
  }

  constructor(private formBuilder: FormBuilder, private citiesService: CitiesService) {}

  ngOnInit(): void {
    const urlRegex =
      '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,9})+)(/(.)*)?(\\?(.)*)?';
    this.companyForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', [Validators.required, Validators.pattern(urlRegex)]],
      cityId: ['', Validators.required],
      area: ['', Validators.required],
    });
    (async () => {
      this.cities = await this.cityStore.getStoredData().pipe(map((c) => c.cities)).toPromise();
    })();
  }

  continue() {
    if (this.companyForm.valid) {
      const body = this.companyForm.value;
      this.proceed.emit(body);
    }
  }
}
