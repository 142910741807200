import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-sector',
  templateUrl: './company-sector.component.html',
  styleUrls: ['./company-sector.component.scss'],
})
export class CompanySectorComponent implements OnInit {
  @Output('proceed') proceed = new EventEmitter<any>();

  error: string | undefined;

  isLoading = false;
  companyForm: FormGroup;

  sectors: string[] = [
    'Fruits & Vegetables',
    'Butchery & BBQ',
    'Seafood',
    'Organic',
    'Beauty & Cosmetics',
    'Flowers & Plants',
    'Electronics',
    'Home & Garden',
    'Pet Shops',
    'Cakes & Bakery',
    'Fitness & Health',
    'Tea & Coffee',
    'E-Marketplace',
    'E-Commerce',
    'Fashion & Apparel',
    'Perfumes',
    'Jewelry & Watches',
    'Pharma',
    'Haute Couture',
    'Grocery & Supermarkets',
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      interest: [[], Validators.required],
      role: ['', Validators.required],
      sector: ['', Validators.required],
    });
  }

  continue() {
    if (this.companyForm.valid) {
      const body = this.companyForm.value;
      this.proceed.emit(body);
    }
  }
}
