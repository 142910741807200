<div class="container-fluid">
  <div class="row m-row">
    <div class="col-lg-5 right-panel">
      <div *ngIf="!isCompanyLoading">
        <img width="100px" class="logo-img" [src]="companyLogoUrl" />
        <p class="title">Discover {{ env.solutionOwner }} logistics services in {{ country }}</p>
      </div>
      <div class="sm-line"></div>
      <p class="sign-in-p">Register to the future of logistics</p>
      <img class="left-intro" src="/assets/images/ahoy-intro.svg" />
    </div>
    <div class="col-lg-7 main-content">
      <app-company-basic-info [hidden]="step !== 1" (proceed)="proceedFromBasicData($event)"></app-company-basic-info>
      <app-company-sector [hidden]="step !== 2" (proceed)="proceedFromSector($event)"></app-company-sector>
      <app-company-documents [hidden]="step !== 3" [companyData]="companyData"></app-company-documents>
    </div>
  </div>
</div>
