import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CredentialsService } from '../auth/credentials.service';
import { statusIdTypes } from '../../types/types';
import { Order, OrdersResponse, EditOrderRequest, ClaimInsuranceResponse } from './order.type.service';
import { ClaimInsurance } from './details/claim-insurance-dialog/claim-insurance.types';

const PAGE_SIZE = 10;
const routes = {
  locations: () => '/org/merchant/company/locations',
  orders: (c: OrderContext) => {
    return `/org/merchant/order/Get?companyId=${c.companyId}&statusIds=${c.statusIds}`;
  },
  orderDetails: (id: number) => `/org/merchant/Order/Detail?id=${id}`,
  orderHasInvoice: (orderId: number) => `/org/transactions/Payment/GetCODMerchantInvoiceLink?orderId=${orderId}`,
  createOrder: () => '/org/delivery/Order/Create',
  createPreOrder: () => '/org/delivery/PreOrder/create',
  createPreExpressOrder: () => `/org/delivery/express/preExpressOrder`,
  createExpressOrder: () => `/org/delivery/express/createOrder`,
  createPreSubscriptions: () => `/org/delivery/Subscription/PreSubscription`,
  createSubscription: () => `/org/delivery/Subscription/NewSubscription`,
  confirmPreOrder: () => '/org/merchant/PreOrder/CreateNewOrder',
  editNewOrder: () => '/org/merchant/PreOrder/UpdateOrder',
  editNewWhiteGlovesOrder: () => '/org/merchant/whitegloves/editorder',
  editInProgressOrder: () => '/org/merchant/Order/EditOrder',
  cancelOrder: () => '/org/delivery/Order/Cancel',
  forceCancelOrder: () => '/org/delivery/Order/ForceCancel',
  uploadInvoice: (orderId: number) => `/org/transactions/Payment/UploadOrderCODMerchantInvoice?orderId=${orderId}`,
  getCounters: (companyId: string) => `/org/delivery/Order/GetCounter?companyId=${companyId}`,
  getCustomerAddressByPhone: (phone: string) => `/org/delivery/Order/findCustomerAddressByPhone?phone=${phone}`,
  getAvailableScheduleWindow: () => `/org/merchant/PreOrder/FindAvailableScheduleWindows`,
  getOrderHistory: (companyId: string, pageNumber: number, pageSize: number, filter: string, search: string) => {
    const url = `/org/merchant/order/Get?companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusIds=${filter}`;
    if (filter !== 'all') {
      if (search !== '') {
        return `/org/merchant/order/Get?companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusIds=${filter}&companyTrackId=${search}`;
      }
      return `/org/merchant/order/Get?companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusIds=${filter}`;
    }

    if (search !== '') {
      return `${url}&companyTrackId=${search}`;
    }
    return url;
  },
  validateCustomerLocation: () => `/org/delivery/validate-geocoordinates`,
  getCityIdByLatLong: (lat: string, lng: string) =>
    `/org/delivery/Location/GetCityIdByLatLong?latitude=${lat}&longitude=${lng}`,
  getMetadataDetails: (orderId: number) => `/org/delivery/Order/GetMetadataDetails?orderId=${orderId}`,
  getMetadataSummary: (orderId: number) => `/org/delivery/Order/GetMetadataSummary?orderId=${orderId}`,
  editOrderLimitation: (orderId: number) => `/org/merchant/Order/EditOrderLimitation?orderId=${orderId}`,
  exportToExcel: () => `/org/merchant/Company/ExportOrderData`,
  createReorderPreOrder: () => '/org/merchant/ReOrder/CreatePreOrder',
  createPriorityPreOrder: () => '/org/merchant/ReOrder/CreatePriorityPreOrder',
  claimInsurance: () => '/org/merchant/insurance/submitClaim',
  getClaimReasons: () => '/org/merchant/insurance/claimReasons',
  getImageUrl: () => '/org/merchant/insurance/claimPicUploadLink',
};

export interface OrderContext {
  companyId?: string;
  upcoming?: boolean;
  pending?: boolean;
  inProgress?: boolean;
  completed?: boolean;
  cancelled?: boolean;
  failed?: boolean;
  outForReturn?: boolean;
  returned?: boolean;
  companyTrackId?: string;
  statusIds?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  orderDetailsCache = {};
  orderStatusId: typeof statusIdTypes;
  private cachedLocations: any;
  getLocationsObservable: Observable<any>;

  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService) {}

  getOrders(context: OrderContext, pageNumber = 0): Observable<OrdersResponse> {
    // load auth company id if the context doesn't contain one
    if (!context.companyId) {
      context.companyId = this.credentialsService.credentials.companyId;
    }

    let url = this.addPaginationToURL(routes.orders(context), pageNumber);
    url += `&companyTrackId=${context.companyTrackId}`; // search param to all orders types

    return this.httpClient.get<OrdersResponse>(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  private addPaginationToURL(url: string, pageNumber: number) {
    return `${url}&pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`;
  }

  /**
   * get order history
   * @param pageNumber
   * @param pageSize
   * @param statusId
   */
  getOrderHistory(
    companyId: string,
    pageNumber: number,
    pageSize: number,
    filter: string = '',
    search: string = ''
  ): Observable<any> {
    return this.httpClient.get(routes.getOrderHistory(companyId, pageNumber, pageSize, filter, search), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  exportToExcel(startDate: Date, endDate: Date = new Date()): Observable<any> {
    if (!startDate) {
      startDate = new Date();
      startDate.setFullYear(2017);
    }
    return this.httpClient.post(
      routes.exportToExcel(),
      {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  /**
   * get the order details based on the order id,
   * it can return a cached order for faster performance
   * or load the latest from the API,
   * @param id the id of the order
   * @param cached whether the response should read from the cached order or load a new one
   */
  getOrderDetails(id: number, cached: boolean = true): Observable<Order> {
    // check if cached flag is set to true
    if (cached) {
      // check if the order id already in the cache
      // otherwise it will load the order anyway
      if (id in this.orderDetailsCache) {
        return of(this.orderDetailsCache[id]);
      }
    }
    return this.httpClient
      .get(routes.orderDetails(id), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(
        map((body: Order) => {
          this.orderDetailsCache[id] = body;
          if (body.temperatureTypeId) {
            body.temperature = this.getTemperatureLabel(body.temperatureTypeId);
          }
          return body;
        })
      );
  }

  getTemperatureLabel(temperatureTypeId: number) {
    const tempMap = {
      11: 'Dry',
      12: 'Ambient',
      21: 'Chilled',
      22: 'Frozen',
    };
    return tempMap[temperatureTypeId ?? ''];
  }

  getCounters(): Observable<any> {
    const companyId = this.credentialsService.credentials.companyId;
    return this.httpClient.get(routes.getCounters(companyId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createOrder(orderInformation: any): Observable<any> {
    return this.httpClient
      .post(routes.createOrder(), orderInformation, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(map((body: any) => body));
  }

  createPreOrder(orderInformation: any): Observable<any> {
    return this.httpClient.post(routes.createPreOrder(), orderInformation, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createPreExpressOrder(order: any): Observable<any> {
    return this.httpClient.post(routes.createPreExpressOrder(), order, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createExpressOrder(body: any) {
    return this.httpClient.put(routes.createExpressOrder(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createPreSubscription(body: any): Observable<any> {
    return this.httpClient.post(routes.createPreSubscriptions(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createSubscription(preSubscriptionId: string): Observable<any> {
    return this.httpClient.post(
      routes.createSubscription(),
      {
        preSubscriptionId,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  confirmPreOrder(body: any): Observable<any> {
    return this.httpClient.post(routes.confirmPreOrder(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  editNewOrder(orderInformation: any): Observable<any> {
    return this.httpClient.post(routes.editNewOrder(), orderInformation, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  editNewWhiteGlovesOrder(orderInformation: any): Observable<any> {
    return this.httpClient.post(routes.editNewWhiteGlovesOrder(), orderInformation, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  editInProgressOrder(orderInformation: EditOrderRequest): Observable<Order> {
    return this.httpClient.put<Order>(routes.editInProgressOrder(), orderInformation, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  canCancelOrder(order: any): boolean {
    return order.orderStatusId === 1 || order.orderStatusId === 12;
  }

  getCityIdByLatLong(lat: string, lng: string): Observable<any> {
    return this.httpClient.get(routes.getCityIdByLatLong(lat, lng), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getCustomerInformationByPhone(phone: string): Observable<any> {
    return this.httpClient
      .get(routes.getCustomerAddressByPhone(phone), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(
        map((res) => {
          if (!res) {
            throw throwError('Customer not found');
          }
          return res;
        })
      );
  }

  /**
   * @param order order
   */
  canEditOrder(order: any): boolean {
    return ('' + order.orderStatusId)[0] === '1' || ('' + order.orderStatusId)[0] === '2';
  }

  canReOrder(order: any): boolean {
    return (
      ('' + order.orderStatusId)[0] === '3' ||
      ('' + order.orderStatusId)[0] === '4' ||
      ('' + order.orderStatusId)[0] === '5'
    );
  }

  /**
   * cancel and order
   * @param order order object ti be cancelled
   */
  cancelOrder(order: any): Observable<any> {
    return this.httpClient.post(
      routes.cancelOrder(),
      {
        OrderId: order.orderId,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }
  /**
   * force cancel order
   * @param order order object to be cancelled
   */
  forceCancelOrder(order: any): Observable<any> {
    return this.httpClient.post(
      routes.forceCancelOrder(),
      {
        OrderId: order.orderId,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  /**
   *
   * @param share for sharing the network call for multiple calls
   * @returns
   */
  getLocations(shared: boolean = false): Observable<any[]> {
    if (this.cachedLocations) {
      return of(this.cachedLocations);
    }
    if (shared && this.getLocationsObservable) {
      return this.getLocationsObservable;
    }

    this.getLocationsObservable = this.httpClient
      .get(routes.locations(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      })
      .pipe(
        share(),
        map((body: any) => {
          this.cachedLocations = body;
          return body;
        })
        // catchError(() => of('Couldn\'t load locations :('))
      );

    return this.getLocationsObservable;
  }

  /**
   * check if the invoice been uploaded for this order
   * @param orderId order id which needs to be checked
   */
  hasInvoiceUploaded(orderId: number): Observable<any> {
    return this.httpClient.get(routes.orderHasInvoice(orderId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  /**
   * upload the invoice to that order.
   * @param orderId order id that the invoice belong to
   * @param file the invoice PDF file
   */
  uploadInvoice(orderId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(routes.uploadInvoice(orderId), formData, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getAvailableScheduleWindow(body: any): Observable<any> {
    return this.httpClient.post(routes.getAvailableScheduleWindow(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  validateCustomerLocation(lat: string, lng: string): Observable<any> {
    return this.httpClient.post(
      routes.validateCustomerLocation(),
      { latitude: lat, longitude: lng },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        }),
      }
    );
  }

  getMetadataDetails(orderId: number) {
    return this.httpClient.get(routes.getMetadataDetails(orderId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getMetadataSummary(orderId: number) {
    return this.httpClient.get(routes.getMetadataSummary(orderId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  editOrderLimitation(orderId: number) {
    return this.httpClient.get(routes.editOrderLimitation(orderId), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  capitalizeCustomerProperties(customer: any, camelInd: boolean) {
    for (let prop in customer) {
      let newPropertyName = '';
      if (camelInd) {
        newPropertyName = this.camelCase(prop);
        customer[newPropertyName] = customer[prop];
      } else {
        newPropertyName = this.capitalize(prop);
        customer[newPropertyName] = customer[prop];
      }
      // if the property was already in the correct case it shouldn't be deleted
      if (prop !== newPropertyName) {
        delete customer[prop];
      }
    }
    return customer;
  }

  private capitalize(str: string) {
    if (str.length) {
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    }
  }

  private camelCase(str: string) {
    if (str.length) {
      return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
    }
  }

  getOrderStatus(id: number) {
    switch (id) {
      case 1:
        return 'New';
      case 12:
        return 'Assigned to job';
      case 21:
        return 'In Pickup Task';
      case 22:
        return 'Out For Delivery';
      case 221:
        return 'Delivering Now';
      case 23:
        return 'Out For Return';
      case 3:
        return 'Delivered';
      case 411:
        return 'Failed To Pickup From Company';
      case 423:
        return 'Failed Delivery To Customer';
      case 5:
        return 'Cancelled';
      default:
        return '';
    }
  }
  getOrderStatusId() {
    this.orderStatusId = statusIdTypes;
    return this.orderStatusId;
  }

  createReorderPreOrder(order: any): Observable<any> {
    return this.httpClient.post(routes.createReorderPreOrder(), order, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  createPriorityPreOrder(order: any): Observable<any> {
    return this.httpClient.post(routes.createPriorityPreOrder(), order, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  claimInsurance(body: ClaimInsurance): Observable<ClaimInsuranceResponse> {
    return this.httpClient.post<ClaimInsuranceResponse>(routes.claimInsurance(), body, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  getClaimReasons(): Observable<string[]> {
    return this.httpClient.get<string[]>(routes.getClaimReasons(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
      }),
    });
  }

  generateImageUrl() {
    return this.httpClient.get(routes.getImageUrl(), {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        'Cache-Control': 'no-cache',
        'Accept-Language': 'en',
      }),
    });
  }

  uploadImgToLink(body: any) {
    return this.httpClient.put(body.link, body.img, {
      headers: new HttpHeaders({
        'Content-Type': 'image/png',
        'x-ms-blob-type': 'BlockBlob',
      }),
    });
  }
}
