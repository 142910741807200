<div class="popup-container">
  <img *ngIf="!data.hideImage" src="/assets/images/delete-icon.svg" />
  <h3>{{ data.header ? data.header : 'Are you sure you want to delete this item?' }}</h3>
  <p>{{ data.msg ? data.msg : 'Make sure you do not delete important items' }}</p>
  <button mat-raised-button color="primary" (click)="cancel()">
    {{ data.cancelBtnLabel ? data.cancelBtnLabel : 'Cancel' }}
  </button>
  <button mat-stroked-button color="warn" class="delete-btn" (click)="confirm()">
    {{ data.confirmBtnLabel ? data.confirmBtnLabel : 'Delete' }}
  </button>
</div>
