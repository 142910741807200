import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalEventsService } from '@app/global-events.service';
import { QuickActionsService } from '@app/quick-action/quick-actions.service';
import { finalize } from 'rxjs/operators';
import { UpdateAmountComponent } from '../dialogs/update-amount/update-amount.component';

@Component({
  selector: 'app-payment-is-wrong',
  templateUrl: './payment-is-wrong.component.html',
  styleUrls: ['./payment-is-wrong.component.scss'],
})
export class PaymentIsWrongComponent implements OnInit {
  isLoading = false;

  @Input() order: any;

  action: any;

  constructor(
    private quickActionsService: QuickActionsService,
    private dialog: MatDialog,
    private globalEventsService: GlobalEventsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.action = activeActions && activeActions.length > 0 ? activeActions[0] : null;
  }

  rejectRequest() {
    this.respond(0, 22);
  }

  updateAmount() {
    const amountDialog = this.dialog.open(UpdateAmountComponent, {
      width: '800px',
      height: '600px',
      data: {
        action: this.action,
        order: this.order,
      },
    });
    amountDialog.afterClosed().subscribe((res: any) => {
      if (res && res.amount) {
        this.respond(res.amount, 21);
      }
    });
  }

  private respond(amount: number, resolution: number) {
    const body = {
      quickActionId: this.action.quickActionId,
      codAmount: amount,
      resolutionTypeId: resolution,
    };
    this.isLoading = true;
    this.quickActionsService
      .updateCodAmount(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.globalEventsService.onQuickActionResolved.emit(this.action);
        })
      )
      .subscribe(
        (res: any) => {},
        (err: any) => {}
      );
  }
}
