<div class="dialog-container">
  <h3 class="title">Update Payment</h3>
  <p class="label">
    The customer saying the amount for this order is wrong, <br />
    Please enter the updated amount
  </p>
  <h3 class="price">{{ currency }} {{ data?.order?.paymentCollected }}</h3>
  <p class="original-amount">original amount</p>
  <p>
    ORDER ID: #{{ data?.order?.orderId }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; REF: #{{
      data?.order?.companyOrderTrackId
    }}
  </p>
  <form [formGroup]="priceForm">
    <mat-form-field appearance="outline">
      <mat-label>Please enter the amount</mat-label>
      <input
        matInput
        type="number"
        min="0.00"
        max="10000.00"
        step="0.25"
        placeholder="Ex: 50"
        formControlName="CashAmount"
      />
    </mat-form-field>
  </form>
  <div>
    <button class="proceed-btn" mat-flat-button color="primary" (click)="proceed()">PROCEED</button>
  </div>
  <div class="cancel-btn" (click)="cancel()">
    <a>CANCEL</a>
  </div>
</div>
