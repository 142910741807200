<div class="form-div custom-card">
  <h3>Create your company</h3>
  <p class="sub-label">Together.. we are changing movement</p>
  <div class="login-error" [hidden]="!error || isLoading" translate>
    <!-- Username or password incorrect. -->
  </div>
  <div class="login-fields" fxLayout="column">
    <h5>Upload your legal documents</h5>

    <div class="form-col-div">
      <h4 class="card-title">Upload VAT Registration Certificate</h4>
      <div class="card document-card">
        <div class="file-wrapper">
          <input #vatInput type="file" id="file" (change)="onVatSelected($event)" />
          <span class="upload-btn">{{ vat ? 'File: ' + vat.name : 'Choose file OR Drag here' }}</span>
          <p class="file-format-text">Required Format : <b class="focused-text">.PDF, .PNG, .JPG</b></p>
          <p class="max-size-text">Max File Size : <b class="focused-text">10MB</b></p>
        </div>
      </div>
    </div>
    <br />
    <div class="form-col-div">
      <h4 class="card-title">Upload Trading License <span>(Company Identification)</span></h4>
      <div class="card document-card">
        <div class="file-wrapper">
          <input #tradeLicenseInput type="file" id="file" (change)="onTradeLicenseSelected($event)" />
          <span class="upload-btn">{{ tradeLicense ? 'File: ' + tradeLicense.name : 'Choose file OR Drag here' }}</span>
          <p class="file-format-text">Required Format : <b class="focused-text">.PDF, .PNG, .JPG</b></p>
          <p class="max-size-text">Max File Size : <b class="focused-text">10MB</b></p>
        </div>
      </div>
    </div>
    <br />
    <button mat-raised-button color="primary" (click)="create()" [disabled]="!vat || !tradeLicense || isLoading">
      <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
      <span translate>Create your company</span>
    </button>
  </div>
</div>
