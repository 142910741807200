import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-action-bar-wrapper',
  templateUrl: './quick-action-bar-wrapper.component.html',
  styleUrls: ['./quick-action-bar-wrapper.component.scss'],
})
export class QuickActionBarWrapperComponent implements OnInit, AfterViewInit {
  @Input() order: any;

  actionTypeId = 1;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const activeActions: any[] = this.order.quickActions?.filter((action: any) => {
      return action.statusId === 1;
    });
    this.actionTypeId = activeActions && activeActions.length > 0 ? activeActions[0].typeId : 1;
    this.cdr.detectChanges();
  }
}
