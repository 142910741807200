import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '@app/analytics.service';
import { environment } from '@env/environment';
import { keys } from 'lodash';
import { Interface } from 'readline';

export interface Action {
  name: string;
  emit: () => void;
}

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {
  onAnalyticsLog = new EventEmitter<any>();
  errorMsg = '';
  img = '/assets/images/dialogs/error.svg';
  errorCode: any;
  env = environment;
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<ErrorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data instanceof String) {
      this.errorMsg = this.data.toString();
    }
    if (this.data instanceof Object) {
      const error = this.data.error;
      const errorCodes = this.data?.errorCodes;
      if (error) {
        if (error instanceof Object) {
          if (error?.error?.code && keys(errorCodes).includes(error?.error?.code.toString())) {
            this.errorCode = errorCodes[error?.error?.code];
          } else if (error.error && error.error.error) {
            this.errorMsg = error.error.error;
          } else {
            this.errorMsg = JSON.stringify(error.error);
          }
        } else {
          this.errorMsg = this.data.error;
        }
      } else {
        this.errorMsg = JSON.stringify(this.data);
      }
    }
    const analyticsEvent: AnalyticsEvent = {
      eventName: 'dialog_error',
      data: {
        error_message: this.errorMsg,
      },
    };
    this.onAnalyticsLog.emit(analyticsEvent);
  }

  action() {
    if (this.data && this.data.action && this.data.action.emit) {
      this.data.action.emit();
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
