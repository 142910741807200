import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      if (request.url.startsWith('/org')) {
        request = this.getOriginalUrl(request);
      } else {
        request = request.clone({ url: environment.serverUrl + request.url });
      }
    }
    return next.handle(request);
  }

  private getOriginalUrl(request: HttpRequest<any>) {
    if (request.url.startsWith('/org/skus')) {
      request = request.clone({
        url: environment.skuUrl + request.url.replace('/org/skus', ''),
      });
    } else if (request.url.startsWith('/org/vs')) {
      request = request.clone({
        url: environment.vsUrl + request.url.replace('/org/vs', ''),
      });
    } else if (request.url.startsWith('/org/delivery')) {
      request = request.clone({
        url: environment.deliveryUrl + request.url.replace('/org/delivery', ''),
      });
    } else if (request.url.startsWith('/org/merchant')) {
      request = request.clone({
        url: environment.merchantUrl + request.url.replace('/org/merchant', ''),
      });
    } else if (request.url.startsWith('/org/transactions')) {
      request = request.clone({
        url: environment.transactionsUrl + request.url.replace('/org/transactions', ''),
      });
    } else if (request.url.startsWith('/org/profile')) {
      request = request.clone({
        url: environment.profileUrl + request.url.replace('/org/profile', ''),
      });
    } else if (request.url.startsWith('/org/token')) {
      request = request.clone({
        url: environment.ahoyAuthUrl + request.url.replace('/org/token', `/${environment.activeDirectoryUrl}`),
      });
    } else if (request.url.startsWith('/org/google')) {
      request = request.clone({
        url: environment.googlePlacesUrl + request.url.replace('/org/google', ''),
      });
    } else if (request.url.startsWith('/org/configuration')) {
      request = request.clone({
        url: environment.configurationUrl + request.url.replace('/org/configuration', ''),
      });
    }

    return request;
  }
}
