// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// ....
// ....
// ....
// ....
import { env } from './.env';

export const environment = {
  production: false,
  environmentName: 'demo',
  hmr: false,
  version: env.npm_package_version,
  solutionOwner: 'Comet',
  appName: 'Merchant-KSA-Dev',
  appCommercialName: 'Merchant Portal',
  supportEmail: 'care@ahoy.ae',
  developerPortalUrl: 'https://ahoyapis.developer.azure-api.net',
  defaultCountryCode: 'AE',

  // ahoy apis
  signupURL: 'https://get.devcomet.net/auth/signup',
  serverUrl: 'https://dev-comet-apis.azure-api.net',
  skuUrl: 'https://dev-comet-apis.azure-api.net',
  vsUrl: 'https://dev-comet-apis.azure-api.net/vstoremerchant',
  ahoyAuthUrl: 'https://devahoy.b2clogin.com',
  authAuthApi: 'https://dev-comet-apis.azure-api.net/uaeauth',
  deliveryUrl: 'https://dev-comet-apis.azure-api.net/delivery',
  merchantUrl: 'https://dev-comet-apis.azure-api.net/merchant',
  transactionsUrl: 'https://dev-comet-apis.azure-api.net/transaction',
  profileUrl: 'https://dev-comet-apis.azure-api.net/profile',
  configurationUrl: 'https://dev-comet-apis.azure-api.net/vendor/configuration',

  // google apis
  googlePlacesUrl: 'https://maps.googleapis.com',

  // apps
  androidMerchantAppUrl: 'https://play.google.com/store/apps/details?id=com.theahoyapp.merchant',
  iosMerchantAppUrl: 'https://apps.apple.com/us/app/Ahoy-dmcc/id1569740692',

  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],

  google_api_key: 'AIzaSyAAVVmfkjLNhBMOkkTgDbuuRw5gJbPc6rI',
  here_maps_key: 'xZwPJLh-cdNe9xEslxQrTeDIHsHFIeo7WnJra4S-i_s',
  activeDirectoryUrl: 'devahoy.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_MobileSignIn',
  activeDirectoryClientId: '129cf170-113d-4225-a152-6cde2b9c7e8c',
  activeDirectoryScope: 'openid 129cf170-113d-4225-a152-6cde2b9c7e8c offline_access',

  forgetPasswordLink: '/reset-password',

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: 'AIzaSyDIbW8l-7fT99RkhSloZMaSOKoz4SfO1kE',
    authDomain: 'ahoy-merchant.firebaseapp.com',
    projectId: 'ahoy-merchant',
    storageBucket: 'ahoy-merchant.appspot.com',
    messagingSenderId: '153490454177',
    appId: '1:153490454177:web:dadf20fcb1c8230378e2e0',
    measurementId: 'G-8DRPPNENQW',
  },

  // azure keys
  instrumentationKey: 'fffb3552-6cd8-4a69-b8ed-9edd84364a6c',

  countrySettings: {
    country: 'UAE',
    countryCode: '+971',
    currency: 'AED',
    cities: [
      {
        id: 1,
        name: 'Fujairah',
      },
      {
        id: 2,
        name: 'Ras Al Khaimah',
      },
      {
        id: 3,
        name: 'Sharjah',
      },
      {
        id: 4,
        name: 'Ajman',
      },
      {
        id: 5,
        name: 'Umm Al Quwain',
      },
      {
        id: 6,
        name: 'Dubai',
      },
      {
        id: 7,
        name: 'Al Ain',
      },
      {
        id: 8,
        name: 'Abu Dhabi',
      },
    ],
    availableTemperatures: [12, 21],
    cashAvailable: true,
    cardAvailable: true,
  },

  featuresAvailability: {
    subscription: false,
    bulk: false,
    resetPassword: true,
    domainValidation: true,
    chargeForProofOfDelivery: false,
  },
};
