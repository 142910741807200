import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-select-ecommerce-delivery-method',
  templateUrl: './select-ecommerce-delivery-method.component.html',
  styleUrls: ['./select-ecommerce-delivery-method.component.scss'],
})
export class SelectEcommerceDeliveryMethodComponent implements OnInit {
  selectedItem = 0;
  env = environment;
  constructor(
    public dialogRef: MatDialogRef<SelectEcommerceDeliveryMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {}

  proceed() {
    const orderId = this.data?.orderId;
    const url = this.selectedItem === 0 ? `/order/shopify/create/${orderId}` : `order/shopify/express/${orderId}`;
    this.router.navigateByUrl(url);
    this.dialogRef.close();
  }
}
