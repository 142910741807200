import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CoreService } from '@app/@core/services/core.service';
import { SubscriptionManager } from '@app/@core/SubscriptionManager';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  private subscriptionManager = new SubscriptionManager();

  constructor(private coreService: CoreService) {}

  public ngOnInit(): void {
    this.setInitialIndicatorValues();
    this.setupConditionalValidators();
  }

  private get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  private get passwordMin(): AbstractControl {
    return this.form.get('passwordMin') as AbstractControl;
  }

  private get passwordDigit(): AbstractControl {
    return this.form.get('passwordDigit') as AbstractControl;
  }

  private get passwordUpperCase(): AbstractControl {
    return this.form.get('passwordUpperCase') as AbstractControl;
  }

  private get passwordLowerCase(): AbstractControl {
    return this.form.get('passwordLowerCase') as AbstractControl;
  }

  private get passwordSpecial(): AbstractControl {
    return this.form.get('passwordSpecial') as AbstractControl;
  }

  private setIndicatorValues(controlValue: string): void {
    controlValue.length >= 8 ? this.passwordMin.setValue(true) : this.passwordMin.setValue(false);
    this.passwordSpecial.setValue(this.coreService.RegexConstants.SYMBOL_REGEX.test(controlValue));
    this.passwordDigit.setValue(this.coreService.RegexConstants.DIGIT_REGEX.test(controlValue));
    this.passwordUpperCase.setValue(this.coreService.RegexConstants.UPPERCASE_REGEX.test(controlValue));
    this.passwordLowerCase.setValue(this.coreService.RegexConstants.LOWERCASE_REGEX.test(controlValue));
  }

  /** Set the indicator values based on the initial password form control value. */
  private setInitialIndicatorValues(): void {
    this.setIndicatorValues(this.password.value);
  }

  /** Listens to the password input in the form and updates the requirements list. */
  private setupConditionalValidators(): void {
    this.subscriptionManager.add(
      this.password.valueChanges.subscribe((controlValue: string) => this.setIndicatorValues(controlValue))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager.destroy();
  }
}
