<div class="form-div custom-card">
  <h3>Create your company</h3>
  <p class="sub-label">Together.. we are changing movement</p>
  <form (ngSubmit)="continue()" [formGroup]="companyForm" novalidate>
    <div class="login-error" [hidden]="!error || isLoading" translate>
      <!-- Username or password incorrect. -->
    </div>
    <br />
    <div class="login-fields" fxLayout="column">
      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Company's Name (legal Name)</mat-label>
        <input
          type="text"
          matInput
          formControlName="companyName"
          autocomplete="name"
          [placeholder]="'Company Name' | translate"
          required
        />
        <mat-error *ngIf="companyForm.controls.companyName.invalid && companyForm.controls.companyName.touched">
          <span translate>Name is required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Company's Email</mat-label>
        <input
          type="text"
          matInput
          formControlName="email"
          autocomplete="email"
          [placeholder]="'Company Email' | translate"
          required
        />
        <mat-error *ngIf="companyForm.controls.email.invalid && companyForm.controls.email.touched">
          <span translate>Email is required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Company's Website</mat-label>
        <input
          type="text"
          matInput
          formControlName="website"
          autocomplete="website"
          [placeholder]="'Website' | translate"
          required
        />
        <mat-error *ngIf="companyForm.controls.website.invalid && companyForm.controls.website.touched">
          <span translate>Website is required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="citiesStatus$ | async as status" [hideRequiredMarker]="true" appearance="outline">
        <mat-label
          >City
          <span [ngSwitch]="status">
            <small *ngSwitchCase="apiResponseStatus.LOADING" class="text-muted">
              <mat-spinner diameter="15"></mat-spinner> Loading...
            </small>
            <small *ngSwitchCase="apiResponseStatus.ERROR" class="text-danger">
              <mat-icon>error</mat-icon> Error
            </small>
          </span>
        </mat-label>
        <mat-select *ngIf="cities" formControlName="cityId" name="city">
          <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Address</mat-label>
        <input
          type="text"
          matInput
          formControlName="area"
          autocomplete="area"
          [placeholder]="'Address' | translate"
          required
        />
        <mat-error *ngIf="companyForm.controls.area.invalid && companyForm.controls.area.touched">
          <span translate>Address is required</span>
        </mat-error>
      </mat-form-field>

      <br />
      <button mat-raised-button color="primary" type="submit" [disabled]="companyForm.invalid || isLoading">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <span translate>Proceed</span>
      </button>
    </div>
  </form>
</div>
