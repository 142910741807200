import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { AuthenticationService } from './authentication.service';
import { CredentialsService } from './credentials.service';
import { fade, slide, swipe } from '@app/animations';
import { Config } from 'ng-otp-input/lib/models/config';
import { NgOtpInputComponent } from 'ng-otp-input';
import { DomainInfo } from '@app/models/domain-info.model';
import { StorageService } from '@app/@core/services/storage/storage.service';
import { StorageKey } from '@app/@core/services/storage/storage-key';
import { CountriesService } from '@app/@core/services/countries.service';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fade, slide, swipe],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;
  version: string | null = environment.version;
  error: string | undefined;
  env = environment;
  loginForm!: FormGroup;
  otpForm!: FormGroup;
  verifyOtpForm!: FormGroup;

  isLoading = false;
  isCompanyLoading = true;
  phones: any[] = [];
  credentials: any = {};

  state = 1; // 1 -> login, 2 -> otp choose number, 3 -> enter otp

  forgetPasswordLink = '';
  get country() {
    return {
      name: CountriesService.countrySettings.country,
      code: CountriesService.countrySettings.countryCode,
    };
  }
  companyLogoUrl: string = '/assets/icons/iot-logo.png';

  otpConfig: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
    inputStyles: {
      'font-size': '18px',
      'margin-bottom': '0.5em',
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
    this.forgetPasswordLink = environment.forgetPasswordLink;
  }

  ngOnInit() {
    this.setCompanyDetails();
  }

  setCompanyDetails() {
    const domainInfo: DomainInfo = this.storageService.getData(StorageKey.domainInfo);
    if (domainInfo?.isValidDomain) {
      this.companyLogoUrl = domainInfo.companyLogoUrl || this.companyLogoUrl;
      this.env.solutionOwner = domainInfo.companyName || this.env.solutionOwner;
    }
    this.isCompanyLoading = false;
  }

  ngOnDestroy() {}

  login() {
    // if the user using phone number
    // and there is no + in front of the number -> add it
    const phone = this.loginForm.get('username').value;
    if (phone * 1 && phone[0] !== '+') {
      this.loginForm.get('username').setValue(`+${phone}`);
    }

    this.isLoading = true;
    this.authenticationService
      .login(this.loginForm.value)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials) => {
          // TODO do the OPT here
          this.credentials = credentials;

          this.showOTP(credentials);
        },
        (error) => {
          log.error('error');
          this.error = error;
        }
      );
  }

  checkIfUserIsMerchant(credentials: any) {
    if (credentials.accessToken) {
      this.authenticationService.getCurrentUserAccess(credentials.accessToken).subscribe(
        (res: any) => {
          const isMerchant = res.services.find((s: any) => s.id === 7) ? true : false;
          if (!isMerchant) {
            this.error = 'You are not allowed to access the portal';
            this.authenticationService.logout().subscribe((res) => {
              location.reload();
            });
          }
        },
        (err) => {}
      );
    }
  }

  private showOTP(credentials: any) {
    this.state = 2;
    if (credentials.otpToken) {
      this.isLoading = true;
      this.authenticationService
        .getUserAllPhoneNumbers(credentials.otpToken)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          untilDestroyed(this)
        )
        .subscribe(
          (res) => {
            this.phones = res;
            // set a defaule value for the phone number as the first one on the response
            if (res && res.length > 0) {
              this.otpForm.get('phone').setValue(res[0].phoneNumber);
            }
          },
          (err) => {
            log.error(err);
          }
        );
    }
  }

  sendOtp() {
    let phone = this.otpForm.get('phone').value;
    if (this.credentials.otpToken) {
      this.isLoading = true;
      this.authenticationService
        .sendOtp(this.credentials.otpToken, phone)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (res) => {
            // set the state to verify otp
            this.state = 3;
          },
          (err) => {}
        );
    }
  }

  verifyOTP() {
    let code = this.verifyOtpForm.get('code').value;
    let phone = this.otpForm.get('phone').value;

    if (this.credentials.otpToken) {
      this.isLoading = true;
      this.authenticationService
        .verifyOtp(this.credentials.otpToken, code, phone)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (res) => {
            if (res.accessToken) {
              log.debug('completing the login');
              this.credentials = { ...this.credentials, ...res };
              this.completeLogin(this.credentials);
            }
          },
          (err) => {
            this.error = 'OTP is invalid';
          }
        );
    }
  }

  resendOtp() {
    this.state = 2;
    this.error = null;
    this.verifyOtpForm.reset('');
    this.ngOtpInput.otpForm.reset('');
    this.verifyOtpForm.get('code').setValue('');
  }

  private saveCredentials(credentials: any) {
    const data = {
      username: this.loginForm.get('username').value,
      token: credentials.accessToken,
      companyId: '0',
      refreshToken: credentials.refreshToken,
      companyName: credentials.companyName,
    };
    this.credentialsService.setCredentials(data, true);
  }

  private loadCompanyInformation() {
    this.isLoading = true;
    this.authenticationService
      .getCompanyDetails()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            log.debug('company details loaded', res);
            // company exist
            if (res.length > 0) {
              const _credentials = this.credentialsService.credentials;
              _credentials.companyId = res[0].id;
              _credentials.companyName = res[0].companyName;
              this.credentialsService.setCredentials(_credentials);
              log.debug('logged in, navigating to the dashboard');
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true }).then(() => {
                window.location.reload();
              });
            }
            // no company
            if (res.length === 0) {
              const _credentials = this.credentialsService.credentials;
              this.credentialsService.setCredentials(_credentials);
              this.router.navigateByUrl('/createCompany');
            }
          }
        },
        (err) => {
          log.error('error');
        }
      );
  }

  private completeLogin(credentials: any) {
    this.saveCredentials(credentials);
    this.checkIfUserIsMerchant(credentials);
    this.authenticationService.hasAccess();
    this.loadCompanyInformation();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.otpForm = this.formBuilder.group({
      phone: ['', Validators.required],
    });
    this.verifyOtpForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  onOtpChange(e: any) {
    if (e.length === this.otpConfig.length) {
      this.verifyOtpForm.get('code').setValue(e);
    } else {
      this.verifyOtpForm.get('code').setValue(null);
    }
  }

  isOtpValid() {
    if (this.verifyOtpForm && this.ngOtpInput?.otpForm) {
      this.verifyOtpForm.markAllAsTouched();
      this.ngOtpInput.otpForm.markAllAsTouched();
      return this.verifyOtpForm.valid && this.ngOtpInput.otpForm.valid;
    }
  }
}
