export class CheckStatus {
  static isLoading(status: APIResponseStatus): boolean {
    return status === APIResponseStatus.LOADING;
  }
  static isSuccess(status: APIResponseStatus): boolean {
    return status === APIResponseStatus.SUCCESS;
  }
  static isEmpty(status: APIResponseStatus): boolean {
    return status === APIResponseStatus.EMPTY;
  }
  static isError(status: APIResponseStatus): boolean {
    return status === APIResponseStatus.ERROR;
  }
  static isNotFound(status: APIResponseStatus): boolean {
    return status === APIResponseStatus.NOT_FOUND;
  }
}

export enum APIResponseStatus {
  SUCCESS = 'success',
  LOADING = 'loading',
  EMPTY = 'empty',
  ERROR = 'error',
  NOT_FOUND = 'notFound',
}

export enum APIErrorResponseStatus {
  // 400
  BAD_REQUEST = 'badRequest',

  // 401
  UNAUTHORIZED = 'unauthorized',

  // 403
  FORBIDDEN = 'forbidden',

  // 404
  NOT_FOUND = 'notFound',

  // 409
  CONFLICT = 'conflict',

  // 500
  INTERNAL_SERVER_ERROR = 'internalServerError',

  // 503
  SERVICE_UNAVAILABLE = 'serviceUnavailable',

  // 504
  GATEWAY_TIMEOUT = 'gatewayTimeout',

  // 0
  NO_INTERNET = 'noInternet',

  // 1
  UNKNOWN = 'unknown',
}
