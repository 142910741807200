import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      width: '700px',
      panelClass: 'custom-dialog',
      disableClose: true,
      backdropClass: 'backdrop-background',
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }
}
