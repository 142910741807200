// tslint:disable: max-line-length

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { environment } from '@env/environment';
import { EnvironmentGuard } from './@shared/guards/environment.guard';
import { AuthenticationGuard } from './auth';
import { RefreshTokenGuard } from './auth/refresh-token.guard';
import { RoleGuard } from './auth/role.guard';

const routes: Routes = [
  Shell.childRoutes([{ path: 'order', loadChildren: () => import('./order/order.module').then((m) => m.OrderModule) }]),
  Shell.childRoutes([
    {
      path: 'bulk',
      loadChildren: () => import('./bulk-order/bulk-order.module').then((m) => m.BulkOrderModule),
      data: {
        isFeatureAvailable: environment.featuresAvailability.bulk,
      },
      canActivate: [EnvironmentGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: 'subscription',
      loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
      data: {
        isFeatureAvailable: environment.featuresAvailability.subscription,
      },
      canActivate: [EnvironmentGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: 'sms',
      loadChildren: () => import('./sms/sms.module').then((m) => m.SmsModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'finance',
      loadChildren: () => import('./finance/finance.module').then((m) => m.FinanceModule),
      canActivate: [RoleGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: 'invoice',
      loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule),
      canActivate: [RoleGuard],
    },
  ]),
  Shell.childRoutes([
    { path: 'location', loadChildren: () => import('./location/location.module').then((m) => m.LocationModule) },
  ]),
  // Shell.childRoutes([{ path: 'sop', loadChildren: () => import('./sop/sop.module').then((m) => m.SopModule) }]),
  Shell.childRoutes([
    { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then((m) => m.EcommerceModule) },
  ]),
  Shell.childRoutes([
    {
      path: 'services',
      loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
      data: {
        isFeatureAvailable: environment.featuresAvailability.bulk,
      },
      canActivate: [EnvironmentGuard],
    },
  ]),
  Shell.childRoutes([
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
  ]),
  // Shell.childRoutes([{ path: 'help', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) }]),
  Shell.childRoutes([{ path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) }]),
  // { path: 'friday', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },
  {
    path: 'setup',
    canActivate: [AuthenticationGuard, RefreshTokenGuard],
    loadChildren: () => import('./account-setup/account-setup.module').then((m) => m.AccountSetupModule),
  },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
  declarations: [],
})
export class AppRoutingModule {}
