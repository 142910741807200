<div class="form-div custom-card">
  <h3>Welcome to {{ env.solutionOwner }} Community</h3>
  <p class="sub-label">Together.. we are changing movement</p>
  <form (ngSubmit)="register()" [formGroup]="registerForm" novalidate>
    <div class="login-error" [hidden]="!error || isLoading" translate>
      <!-- Username or password incorrect. -->
    </div>
    <div class="login-fields" fxLayout="column">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              type="text"
              matInput
              formControlName="firstName"
              autocomplete="firstName"
              [placeholder]="'First Name' | translate"
              required
            />
            <mat-error *ngIf="registerForm.controls.firstName.invalid && registerForm.controls.firstName.touched">
              <span translate>First name is required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              type="text"
              matInput
              formControlName="lastName"
              autocomplete="lastName"
              [placeholder]="'Last Name' | translate"
              required
            />
            <mat-error *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.touched">
              <span translate>Last name is required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Email Address</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          autocomplete="lastName"
          [placeholder]="'Email' | translate"
          required
        />
        <mat-error *ngIf="registerForm.controls.email.invalid && registerForm.controls.email.touched">
          <span translate>Please enter a valid email</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input
          type="tel"
          matInput
          formControlName="phoneNumber"
          autocomplete="phoneNumber"
          [placeholder]="'Phone Number' | translate"
          required
        />
        <mat-hint>Hint: {{ countryCode + 'XXXXXXXXX' }}</mat-hint>
        <mat-error *ngIf="registerForm.controls.phoneNumber.invalid && registerForm.controls.phoneNumber.touched">
          <span translate>Please enter a valid phone number</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" [placeholder]="'Password' | translate" required />
        <!-- <mat-error *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.touched">
          <span translate>
            Password should be 8 characters or more <br />
            and should include at least 3 of the following. <br />
            - it should contain at least 1 uppercase letter <br />
            - it should contain at least 1 lowercase letter <br />
            - it should contain at least 1 number <br />
            - it should contain at least 1 special character <br />
          </span>
        </mat-error> -->
      </mat-form-field>
      <app-password-strength [form]="registerForm"></app-password-strength>
      <button mat-raised-button color="primary" type="submit" [disabled]="registerForm.invalid || isLoading">
        <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader>
        <span translate>Register</span>
      </button>
    </div>
  </form>
</div>
