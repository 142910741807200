import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessDialogComponent } from '@app/@shared/dialogs/success-dialog/success-dialog.component';
import { ErrorPopupComponent, Action } from '@app/@shared/error-popup/error-popup.component';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ErrorService } from './error.service';
declare global {
  interface Window {
    MSStream: any;
  }
}
// password regex
interface Constants {
  readonly PASSWORD_REGEX: RegExp;
  readonly DIGIT_REGEX: RegExp;
  readonly SYMBOL_REGEX: RegExp;
  readonly UPPERCASE_REGEX: RegExp;
  readonly LOWERCASE_REGEX: RegExp;
}
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public UNRECOGNIZED = 0;
  public ANDROID = 1;
  public IOS = 2;

  public CHROME = 1;
  public FIREFOX = 2;
  public SAFARI = 3;

  constructor(private errorService: ErrorService) {}

  showSuccessDialog(title: string, msg: string, img?: string, btnTxt?: string, action?: any) {
    Swal.fire({
      title: title ? title : 'Success!',
      html: msg,
      icon: !img ? 'success' : null,
      imageUrl: img ? img : null,
      confirmButtonColor: '#242f5a',
      confirmButtonText: action?.exportPayoutUrl ? 'EXPORT PAYOUT TRANSACTIONS' : btnTxt ? btnTxt : 'Ok',
      showCancelButton: action?.exportPayoutUrl ? true : false,
      cancelButtonText: action?.exportPayoutUrl ? btnTxt : 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (action?.exportPayoutUrl) {
          window.open(action?.exportPayoutUrl);
        }
      }
    });
  }

  showErrorDialog(msg: any, action: Action = null) {
    this.errorService.showErrorDialog(msg, action);
  }

  /**
   * convert object properties to different statuses
   * @param obj the object to be converted
   * @param camel set to true if the target should be in camel case status.
   */
  convertProperties(obj: any, camel: boolean) {
    for (let prop in obj) {
      let newPropertyName = '';
      if (camel) {
        newPropertyName = this.camelCase(prop);
        obj[newPropertyName] = obj[prop];
      } else {
        newPropertyName = this.capitalize(prop);
        obj[newPropertyName] = obj[prop];
      }
      // if the property was already in the correct case it shouldn't be deleted
      if (prop !== newPropertyName) {
        delete obj[prop];
      }
    }
    return obj;
  }

  private capitalize(str: string) {
    if (str.length) {
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    }
  }

  private camelCase(str: string) {
    if (str.length) {
      return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
    }
  }

  getHourOfTheDaySlotLabel(hour: number, slotDuration: number) {
    var duration = (hour + slotDuration) % 24;
    return `${this.getHour(hour)} - ${this.getHour(duration)}`;
  }

  getHour(hour: number): string {
    if (hour === 12) {
      return '12:00 PM';
    }
    if (hour === 24 || hour === 0) {
      return '12:00 AM';
    }
    return hour < 12 ? `${hour}:00 AM` : `${hour - 12}:00 PM`;
  }

  /**
   * Compare 2 dates
   * @param date1 date the you want to compare
   * @param date2 the date that you want the endDate to be compared to. default to current date
   * @returns return boolean to indicate if date1 is <= date2
   */
  compareDates(date1: any, date2: any = new Date()): boolean {
    var d2 = moment(date2).format('YYYY-MM-DD');
    var d1 = moment(date1).format('YYYY-MM-DD');
    return d1 <= d2;
  }

  /**
   * this function checks the OS type and return information about which OS is used.
   * @returns number 0 -> unrecognized, 1 -> android, 2 -> ios
   */
  checkOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return this.ANDROID;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return this.IOS;
    }
    return this.UNRECOGNIZED;
  }

  getBrowser() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return this.CHROME;
    }

    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return this.SAFARI;
    }

    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return this.FIREFOX;
    }

    return this.UNRECOGNIZED;
  }

  // store the last feedback date in cookies and set the expiry date to 30 days from the creation date,
  // this will take the cookie name as a string and number for days till the cookie expire
  setCookie(name: string, days: number) {
    var expires = '';
    var value = '';
    if (days) {
      var current_date = new Date();
      var expiry_date = new Date();
      expiry_date.setTime(expiry_date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + expiry_date.toString();
      value = current_date.toString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  // to get the cookie using the name
  getCookie(name: string) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // to get the difference in days between current date and selected date
  getDatesDifferenceInDays(date1: any, date2: any = new Date()) {
    return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  // check if the user can submit feedback based on the last feedback submitted
  canCollectFeedback() {
    const last_feedback = new Date(this.getCookie('last_feedback'));
    const diff = this.getDatesDifferenceInDays(last_feedback);
    return diff > 30;
  }

  RegexConstants: Constants = {
    PASSWORD_REGEX:
      /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/gm,
    DIGIT_REGEX: /[0-9]/,
    SYMBOL_REGEX: /[-+_!@#$%^&*,.?]/,
    UPPERCASE_REGEX: /[A-Z]/,
    LOWERCASE_REGEX: /[a-z]/,
  };
}
