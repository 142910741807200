<div class="terms-popup">
  <h1>Review Terms & conditions and privacy policy</h1>

  <img src="/assets/images/terms.svg" />

  <p>
    Please accept our
    <a color="primary" target="_blank" [href]="tcUrl"> Terms and conditions </a>
    and
    <a color="primary" target="_blank" [href]="privacyUrl"> Privacy policy </a>
    to be able to use the application.
  </p>

  <div>
    <button mat-raised-button class="accept-btn" color="primary" (click)="accept()">I accept</button>
  </div>
</div>
