<div class="container my-container">
  <div class="row home-row">
    <div class="col">
      <div>
        <!-- <h1>Empty Landing page</h1> -->
        <img *ngIf="!showBanner" src="/assets/images/home.png" class="home-img" />

        <div *ngIf="loaded && !showBanner" class="create-order-div">
          <button mat-button color="primary" routerLink="/order/create">
            <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
            Create a new Order
          </button>
        </div>

        <app-promotion *ngIf="loaded && showBanner"></app-promotion>

        <div class="row">
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              New Orders: {{ counters?.newOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Upcoming Orders: {{ counters?.upcomingOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Ready to pickup: {{ counters?.readyForPickupOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              In Progress Orders: {{ counters?.inProgressOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Delivered Orders: {{ counters?.deliveredOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Cancelled Orders: {{ counters?.cancelledOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Failed Orders: {{ counters?.failedOrdersCount }}
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="summary-card" (click)="goToOrders()">
              Returned Orders: {{ counters?.orderReturnedCount }}
            </mat-card>
          </div>
        </div>
      </div>

      <div class="contact-card">
        <p class="contact-us">Contact Us</p>
        <p>
          For assistance or guidance please reach out to us at Email:
          <a *ngIf="contactInfo?.email" href="mailto:{{ contactInfo?.email }}">{{ contactInfo?.email }} </a>
          <span *ngIf="!contactInfo?.email">N/A</span>
          <span class="ml-1">Phone: {{ contactInfo?.phoneNumber || 'N/A' }}</span>
        </p>
      </div>
    </div>
    <!-- <div class="col-lg-3 news-div">
      <app-news></app-news>
    </div> -->
  </div>
</div>
